import produce from "immer";
import { appIdentifier } from "app-constants.js";

// Actions:
const UPDATE_SPACE_RECT = `${appIdentifier}/space/UPDATE_SPACE_RECT`;
const SET_SPACE_OFFSET = `${appIdentifier}/space/SET_SPACE_OFFSET`;
const TRANSLATE_SPACE = `${appIdentifier}/space/TRANSLATE_SPACE`;
const SET_TEMPORAL_SPACE_OFFSET = `${appIdentifier}/space/SET_TEMPORAL_SPACE_OFFSET`;
const SET_PPU = `${appIdentifier}/space/SET_PPU`;

const initialState = {
  rect: {
    left: 0,
    top: 0
  },
  offset: {
    x: 0,
    y: 0
  },
  temporalOffset: {
    x: 0,
    y: 0
  },
  ppu: 40
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  return produce((draft, action) => {
    switch (action.type) {
      case UPDATE_SPACE_RECT:
        draft.rect = action.rect;
        return;

      case SET_SPACE_OFFSET:
        if(isNaN(action.offset.x) || isNaN(action.offset.y))
        {
          throw "Space Offset"
        }
        draft.offset = action.offset;
        return;

      case TRANSLATE_SPACE:
        if(isNaN(action.offset.x) || isNaN(action.offset.y))
        {
          throw "Translate space"
        }
        draft.offset = {
          x: draft.offset.x + action.offset.x,
          y: draft.offset.y + action.offset.y
        };
        return;

      case SET_TEMPORAL_SPACE_OFFSET:
        draft.temporalOffset = action.offset;
        return;

      case SET_PPU:
        draft.ppu = action.ppu;
        return;

      default:
        return state;
    }
  })(state, action);
}

// Action creators
export function updateSpaceRect(rect) {
  return {
    type: UPDATE_SPACE_RECT,
    rect
  };
}

export function setSpaceOffset(offset) {
  return {
    type: SET_SPACE_OFFSET,
    offset
  };
}

export function translateSpace(offset) {
  return {
    type: TRANSLATE_SPACE,
    offset
  };
}

export function setTemporalSpaceOffset(offset) {
  return {
    type: SET_TEMPORAL_SPACE_OFFSET,
    offset
  };
}

export function setPPU(ppu) {
  return {
    type: SET_PPU,
    ppu
  };
}