import React, { useContext, useRef } from "react";
import { useDispatch } from "react-redux";
import { inputContext, useDrawGestureEvents } from "input";
import { removeNode, removeEdge, saveHistory } from "specification";
import { dispatchClearTarget } from "input/modules/hovered-target.js";

export function EraseTool() {
  const dispatch = useDispatch();
  const ic = useContext(inputContext);

  // console.log('render erase tool')

  const initialContextState = {
    start: {
      hoverTarget: {}
    },
    end: {
      hoverTarget: {}
    }
  };
  const gestureContext = useRef(initialContextState);
  
  useDrawGestureEvents(
    ic.layer,
    // start
    (event, context) => {
      gestureContext.current = {
        ...gestureContext.current,
        start: {
          hoverTarget: event.detail.hoverTarget
        }
      };
    },
    // update
    (event, context) => {
    },
    // complete
    (event, context) => {
      
      gestureContext.current = {
        ...gestureContext.current,
        end: {
          hoverTarget: event.detail.hoverTarget
        }
      };

      // Context is a erasable object and mouse down was on the same object as the mouse up
      if (
        gestureContext.current.start.hoverTarget.identifier !== "" &&
        gestureContext.current.start.hoverTarget.type !== "" &&
        gestureContext.current.start.hoverTarget.identifier ===
          gestureContext.current.end.hoverTarget.identifier
      ) {
        if (gestureContext.current.end.hoverTarget.type === "node") {
          dispatch(saveHistory());
          dispatch(
            removeNode(gestureContext.current.start.hoverTarget.identifier)
          );
        } else if (gestureContext.current.end.hoverTarget.type === "edge") {
          dispatch(saveHistory());
          dispatch(
            removeEdge(gestureContext.current.start.hoverTarget.identifier)
          );
        }
      }

      dispatchClearTarget(ic.layer);
      gestureContext.current = initialContextState;
    },
    () => {
      // cancel
      gestureContext.current = initialContextState;
    }
  );

  return <div></div>;
}
