import { isDraftable, produce } from "immer";
// import current from "@reduxjs/toolkit";
import { appIdentifier } from "app-constants.js";
import { not, hasOwnProperty } from "helpers.js";
import { determineSpatialRelationship, validateSpatialRelationBetweenTransforms } from "toolbox/tools/helpers/transform-helpers";
import { remove } from "animejs";
import { current, original } from "@reduxjs/toolkit";

// Actions
const SET_SPECIFICATION = `${appIdentifier}/specification/SET_SPECIFICATION`;
const SET_ACTION_CODE = `${appIdentifier}/specification/SET_ACTION_CODE`;
const ADD_NODE = `${appIdentifier}/specification/ADD_NODE`;
const REMOVE_NODE = `${appIdentifier}/specification/REMOVE_NODE`;
const UPDATE_NODE = `${appIdentifier}/specification/UPDATE_NODE`;
const SET_NODE_NAME = `${appIdentifier}/specification/SET_NODE_NAME`;
const SET_NODE_SMACH_ACTION = `${appIdentifier}/specification/SET_NODE_SMACH_ACTION`;
const ADD_EDGE = `${appIdentifier}/specification/ADD_EDGE`;
const REMOVE_EDGE = `${appIdentifier}/specification/REMOVE_EDGE`;
const UPDATE_EDGE = `${appIdentifier}/specification/UPDATE_EDGE`;
const SET_EDGE_SMACH_OUTCOME = `${appIdentifier}/specification/SET_EDGE_SMACH_OUTCOME`;
// const MOVE_NODE_DOWN = `${appIdentifier}/specification/MOVE_NODE_DOWN`;
const MOVE_NODE_TRANSFORM = `${appIdentifier}/specification/MOVE_NODE_TRANSFORM`;
const SET_NODE_TRANSFORM = `${appIdentifier}/specification/SET_NODE_TRANSFORM`;
const SET_NODE_TRANSFORMS = `${appIdentifier}/specification/SET_NODE_TRANSFORMS`;
const SET_SELECTION = `${appIdentifier}/specification/SET_SELECTION`;
const ADD_TO_SELECTION = `${appIdentifier}/specification/ADD_TO_SELECTION`;
const REMOVE_FROM_SELECTION = `${appIdentifier}/specification/REMOVE_FROM_SELECTION`;
const SAVE_HISTORY = `${appIdentifier}/specification/SAVE_HISTORY`;
const RESTORE_STATE_FROM_HISTORY = `${appIdentifier}/specification/RESTORE_STATE_FROM_HISTORY`;
const RESTORE_STATE_FROM_FUTURE = `${appIdentifier}/specification/RESTORE_STATE_FROM_FUTURE`;

export const initialState = {
  nodes: {
    byIdentifier: {
      start: {
        name: "START",
        identifier: "start",
        parent: "",
        children: [],
        transitions: [],
        entryPoints: {},
        transform: {
          minX: 1,
          maxX: 6,
          minY: 1,
          maxY: 5
        },
        smachAction: {
          identifier: "",
          parameters: ""
        }
      }
    },
    identifiers: ["start"],
    selected: ["start"],
    nodeDistance: {
      start: 0,
    },
    lastAddedIdentifier: "",
  },
  edges: {
    byIdentifier: {
      /*'id1':{
                priority: 1,
                from: '',
                to: '',
                if: {
                    outcome: ''
                },

            }*/
    },
    identifiers: [
      /*id1'*/
    ]
  },
  actionCode: "",
  history: [
    // {
    //   nodes: {},
    //   edges: {},
    //   actionCode: "",
    // }
  ],
  future: []
};

export function saveHistory() {
  return function (dispatch) {
    dispatch({
      type: SAVE_HISTORY,
    });
  };
}

export function undo() {
  return function (dispatch) {
    dispatch({
      type: RESTORE_STATE_FROM_HISTORY,
    });
  };
}

export function redo() {
  return function (dispatch) {
    dispatch({
      type: RESTORE_STATE_FROM_FUTURE,
    });
  };
}

function updateNodeDistances(draftProxy) {
  let draft = draftProxy;
  // console.log("draft", getdraft);
  draft.nodes.nodeDistance = {};
  for (let nodeId of draft.nodes.selected) {
    draft.nodes.nodeDistance[nodeId] = 0;
  }

  let todo = new Set(draft.nodes.selected);
  let done = new Set();

  // console.log("nodesByIdentifier: ", draft.nodes)

  while (todo.size > 0) {
    let nodeId = todo.values().next().value;
    todo.delete(nodeId);
    done.add(nodeId);


    let node = draft.nodes.byIdentifier[nodeId];
    // console.log("current: ", nodeId, node)

    if (node && node.transitions) {
      for (let edgeId of node.transitions) {
        let edge = draft.edges.byIdentifier[edgeId];
        // console.log("edge", edgeId, edge);

        let targetNode;
        if (edge.from.identifier === nodeId) {
          targetNode = edge.to.identifier;
        } else {
          targetNode = edge.from.identifier;
        }
        if (edge && !done.has(targetNode)) {
          todo.add(targetNode)
          draftProxy.nodes.nodeDistance[targetNode] = draft.nodes.nodeDistance[nodeId] + 1;
        }
      }
    }
  }


  // console.log("nodeDistances:", draft.nodes.nodeDistance);
  // action.nodes.forEach(node => set.add(node));
  // draft.nodes.selected = Array.from(set);


  // let a = {
  //   x: 5,
  //   y: 6,
  // }
  // a.x = 7;
  // a["x"] = 9;

  // for(let test of a)


  // draft.nodes.nodeDistance.start = 0;
}

function deepClone(obj) {
  return JSON.parse(JSON.stringify(obj))
}

function isObject(object) {
  return object != null && typeof object === "object";
}

function isDeepEqual(object1, object2) {

  const objKeys1 = Object.keys(object1);
  const objKeys2 = Object.keys(object2);

  if (objKeys1.length !== objKeys2.length) return false;

  for (var key of objKeys1) {
    const value1 = object1[key];
    const value2 = object2[key];

    const isObjects = isObject(value1) && isObject(value2);

    if ((isObjects && !isDeepEqual(value1, value2)) ||
      (!isObjects && value1 !== value2)
    ) {
      return false;
    }
  }
  return true;
}

// Reducer
export default function reducer(state = initialState, action = {}) {
  return produce((draft, action) => {
    switch (action.type) {
      case SAVE_HISTORY:
        if (draft.history === undefined){
          draft.history = []
        }
        let newEntry = {nodes: deepClone(draft.nodes), edges: deepClone(draft.edges), actionCode: deepClone(draft.actionCode)}
        draft.history.push(newEntry)
        draft.future = []
        console.log("save history: ", deepClone(draft.history))
        break;
      case RESTORE_STATE_FROM_HISTORY:
        console.log("undo: ", deepClone(draft.history));
        if(draft.history && draft.history.length > 0){
          if (draft.future === undefined){
            draft.future = []
          }
          let newEntry = {nodes: deepClone(draft.nodes), edges: deepClone(draft.edges), actionCode: deepClone(draft.actionCode)}
          draft.future.push(newEntry)

          let last = draft.history.pop()
          draft.nodes = last.nodes
          draft.edges = last.edges
          draft.actionCode = last.actionCode
        }
        break;
      case RESTORE_STATE_FROM_FUTURE:
        console.log("redo: ", deepClone(draft.future));
        if(draft.future && draft.future.length > 0){
          if (draft.history === undefined){
            draft.history = []
          }
          let newEntry = {nodes: deepClone(draft.nodes), edges: deepClone(draft.edges), actionCode: deepClone(draft.actionCode)}
          draft.history.push(newEntry)

          let last = draft.future.pop()
          draft.nodes = last.nodes
          draft.edges = last.edges
          draft.actionCode = last.actionCode
        }
        break;
      case SET_SPECIFICATION:
        //draft = {
        //    ... action.specification
        //}
        draft.nodes = action.specification.nodes;
        draft.edges = action.specification.edges;
        draft.actionCode = action.specification.actionCode;
        break;
      case SET_ACTION_CODE:
        draft.actionCode = action.actionCode;
        break;
      case ADD_NODE:
        // todo validate newNode and current nodes (e.g. not the same id)

        // Add the node to the specification.
        draft.nodes.byIdentifier[action.node.identifier] = action.node;
        draft.nodes.identifiers.push(action.node.identifier);

        // Resolve dependencies such as hierarchical parent child relations:
        let node = draft.nodes.byIdentifier[action.node.identifier];

        // Update the parent.
        if (node.parent !== "") {
          let parentNode = draft.nodes.byIdentifier[node.parent];

          // Add child.
          if (parentNode.children === undefined) {
            parentNode.children = [];
          }
          parentNode.children.push(node.identifier);

          // Set the default entry to the first child if not already set.
          if (not(hasOwnProperty(parentNode.entryPoints, "default"))) {
            parentNode.entryPoints.default = parentNode.children[0];
          }
        }

        // Update children.
        if (node.children.length > 0) {
          for (let index = 0; index < node.children.length; index++) {
            const childNode = draft.nodes.byIdentifier[node.children[index]];
            childNode.parent = node.identifier;
          }

          // Set the default entry to the first child if not already set.
          if (not(hasOwnProperty(node.entryPoints, "default"))) {
            node.entryPoints.default = node.children[0];
          }
        }
        draft.nodes.lastAddedIdentifier = action.node.identifier
        updateNodeDistances(draft);

        return;
      case REMOVE_NODE:
        // Only node related stuff. Everything else is handled and dispatched in action. (Such as automatically removing edges)

        // Update children:
        for (let i = 0; i < action.node.children.length; i++) {
          const identifier = action.node.children[i];

          draft.nodes.byIdentifier[identifier].parent = action.node.parent;
        }

        // Update parent:

        if (action.node.parent !== "") {
          draft.nodes.byIdentifier[
            action.node.parent
          ].children = draft.nodes.byIdentifier[
            action.node.parent
          ].children.filter(element => element !== action.node.identifier);
        }

        // Remove node:
        delete draft.nodes.byIdentifier[action.node.identifier];
        draft.nodes.identifiers = draft.nodes.identifiers.filter(
          element => element !== action.node.identifier
        );

        // todo validate that an elemnt was removed


        updateNodeDistances(draft);
        return;
      case UPDATE_NODE:{
        // todo: resolve parent child relations

        // todo valdiate that node exists
        draft.nodes.byIdentifier[action.node.identifier] = action.node;
        return;
      }

      case SET_NODE_NAME:
        draft.nodes.byIdentifier[action.nodeIdentifier].name = action.name;
        return;

      case SET_NODE_SMACH_ACTION:
        draft.nodes.byIdentifier[action.nodeIdentifier].smachAction = {
          identifier: action.actionIdentifier,
          parameters: action.actionParameters
        };
        return;

      case ADD_EDGE:
        (() => {
          // todo validate newNode and current nodes (e.g. not the same id)

          // Add the actual edge.
          draft.edges.byIdentifier[action.edge.identifier] = action.edge;
          draft.edges.identifiers.push(action.edge.identifier);

          // Todo check from to types.

          // Add the edge identifier to the involved nodes.
          const nodeIdentifiers = [
            action.edge.from.identifier,
            action.edge.to.identifier
          ];
          for (let i = 0; i < nodeIdentifiers.length; i++) {
            // If the edge is a transition to itself only add the edge once.
            if (i === 0 || nodeIdentifiers[0] !== nodeIdentifiers[1]) {
              draft.nodes.byIdentifier[nodeIdentifiers[i]].transitions.push(
                action.edge.identifier
              );
            }
          }

          updateNodeDistances(draft);

        })();

        return;

      case REMOVE_EDGE:
        (() => {
          // Remove the edge from the node transition lists.
          const nodeIdentifiers = [
            action.edge.from.identifier,
            action.edge.to.identifier
          ];
          for (let i = 0; i < nodeIdentifiers.length; i++) {
            draft.nodes.byIdentifier[
              nodeIdentifiers[i]
            ].transitions = draft.nodes.byIdentifier[
              nodeIdentifiers[i]
            ].transitions.filter(element => element !== action.edge.identifier);
          }

          // Finally, remove the edge.
          delete draft.edges.byIdentifier[action.edge.identifier];
          draft.edges.identifiers = draft.edges.identifiers.filter(
            element => element !== action.edge.identifier
          );

          updateNodeDistances(draft);
        })();

        return;

      case UPDATE_EDGE:
        // todo valdiate that node exists
        draft.edges.byIdentifier[action.edge.identifier] = action.edge;

        updateNodeDistances(draft);

        return;

      case SET_EDGE_SMACH_OUTCOME:
        draft.edges.byIdentifier[action.edgeIdentifier].if.outcome =
          action.outcome;
        return;

      // case MOVE_NODE_DOWN:
      //   draft.nodes.byIdentifier[action.node.identifier] = action.node;
      //   return;

      case MOVE_NODE_TRANSFORM:
        // console.log(action.identifier);
        if (draft.nodes.byIdentifier[action.identifier]) {
          draft.nodes.byIdentifier[action.identifier].transform.minX += action.transform.x;
          draft.nodes.byIdentifier[action.identifier].transform.maxX += action.transform.x;
          draft.nodes.byIdentifier[action.identifier].transform.minY += action.transform.y;
          draft.nodes.byIdentifier[action.identifier].transform.maxY += action.transform.y;
        }
        return;

      case SET_NODE_TRANSFORM:
        if (draft.nodes.byIdentifier[action.identifier]) {
          draft.nodes.byIdentifier[action.identifier].transform = {minX: action.minX, maxX: action.maxX, minY: action.minY, maxY: action.maxY, };
        }
        return;

      case SET_NODE_TRANSFORMS:
        for (let item of action.nodes) {
          if (draft.nodes.byIdentifier[item.identifier]) {
            draft.nodes.byIdentifier[item.identifier].transform = {minX: item.minX, maxX: item.maxX, minY: item.minY, maxY: item.maxY, };
          }
        }
        return;

      case SET_SELECTION:
        draft.nodes.selected = Array.from(new Set(action.nodes));
        updateNodeDistances(draft);
        return;

      case ADD_TO_SELECTION: {
        let set = new Set(draft.nodes.selected);
        action.nodes.forEach(node => set.add(node));
        draft.nodes.selected = Array.from(set);
        updateNodeDistances(draft);
        return;
      }

      case REMOVE_FROM_SELECTION: {
        let set = new Set(draft.nodes.selected);
        action.nodes.forEach(node => set.delete(node));
        draft.nodes.selected = Array.from(set);
        updateNodeDistances(draft);
        return;
      }

      default:
    }
  })(state, action);
}

// Action Creators
export function setSpecification(specification) {
  return function (dispatch, getState, tree) {
    dispatch({
      type: SET_SPECIFICATION,
      specification
    });

    tree.rehydrate(getState().specification);
  };
}

export function setActionCode(actionCode) {
  return function (dispatch) {
    dispatch({
      type: SET_ACTION_CODE,
      actionCode
    });
  };
}

//Go through all the children of the object you are intersecting with recursively and move them all
function recChildrenTransform(item, force, tree, getState, all) {
  if (force !== undefined) {
    tree.remove(item, (a, b) => a.identifier === b.identifier);
    item.minX += force.x;
    item.maxX += force.x;
    item.minY += force.y;
    item.maxY += force.y;
    tree.insert(item);
  }

  for(let child of getState().specification.nodes.byIdentifier[item.identifier].children){
    let childItem = all.find(a => a.identifier === child);
    recChildrenTransform(childItem, force, tree, getState, all)
  }

}
function simulatePhysics(dispatch, getState, tree) {
  let start = performance.now()
  let all = [...tree.all()]
  // console.log("simulatePhysics")

  let time = 0.1;
  for (let i = 0; i < 500; i++) {
    let forces = {
      // identifer: {
      //  x: 10,
      //  y: 5,
      //}
    };

    let startLoopAll = performance.now()
    for (let item of all) {
      let offset = 1;
      let item_with_offset = {
        minX: item.minX - offset,
        maxX: item.maxX + offset,
        minY: item.minY - offset,
        maxY: item.maxY + offset,
      }
      let collisions = tree.fuzzySearchWithoutBorder(item_with_offset);

      let center = {
        x: (item.minX + item.maxX) * 0.5,
        y: (item.minY + item.maxY) * 0.5,
      }

      for (let other of collisions) {
        if (item.identifier === other.identifier)
          continue;

        if (validateSpatialRelationBetweenTransforms(item, other))
          continue;

        const itemNode = getState().specification.nodes.byIdentifier[item.identifier];
        const otherNode = getState().specification.nodes.byIdentifier[other.identifier];
        if (itemNode.parent !== otherNode.parent) {
          continue;
        }

        // console.log(item.identifier, " collides with ", other)

        let otherCenter = {
          x: (other.minX + other.maxX) * 0.5,
          y: (other.minY + other.maxY) * 0.5,
        }

        // Check if we already added a force once
        if (forces[other.identifier] === undefined) {
          forces[other.identifier] = { x: 0, y: 0 };
        }

        forces[other.identifier].x += ((otherCenter.x - center.x) / 10);
        forces[other.identifier].y += ((otherCenter.y - center.y) / 10);
      }
    }
    let endLoopAll = performance.now()

    // console.log(forces)

    let startMoveAll = performance.now()
    const lastAddedIdentifier = getState().specification.nodes.lastAddedIdentifier;
    for (let item of all) {
      // Don't apply forces to newItem so that it stays where it is.
      if (item.identifier === lastAddedIdentifier)
        continue;

      if (forces[item.identifier] !== undefined) {
        let force = forces[item.identifier]
        recChildrenTransform(item, force, tree, getState, all)
      }
    }
    let endMoveAll = performance.now()
  }

  let startDispatch = performance.now()
  // for (let item of all) {
  //   // console.log("set node transform: ", item)
  //   // if (item.identifier === newItem.identifier)
  //   //   continue;

  //   // item.minX = Math.round(item.minX)
  //   // item.maxX = Math.round(item.maxX)
  //   // item.minY = Math.round(item.minY)
  //   // item.maxY = Math.round(item.maxY)
  //   dispatch({
  //     type: SET_NODE_TRANSFORM,
  //     ...item,
  //     // transform: { minX: item.minX, maxX: item.maxX, minY: item.minY, maxY: item.maxY },
  //     // identifier: item.identifier
  //   });
  // }

  dispatch({
    type: SET_NODE_TRANSFORMS,
    nodes: all,
    // transform: { minX: item.minX, maxX: item.maxX, minY: item.minY, maxY: item.maxY },
    // identifier: item.identifier
  });
  let endDispatch = performance.now()

  tree.rehydrate(getState().specification);
  let end = performance.now()
  // console.log("simulate Physics: ", (end - start), "ms, check overlaps: ", (endLoopAll - startLoopAll), "ms, move nodes: ", (endMoveAll - startMoveAll), "ms, dispatch: ", (endDispatch - startDispatch), "ms")
}

function updateRelationships(identifier, tree, getState, dispatch){
  // Determine the parent node and children nodes and augment the node:

  // let rawNode = {...getState().specification.nodes.byIdentifier[identifier]}
  let rawNode = JSON.parse(JSON.stringify(getState().specification.nodes.byIdentifier[identifier]));
  // console.log("updateRelationships for ", identifier, rawNode)

  // Create the spatial tree structure.
  const item = {
    minX: rawNode.transform.minX,
    minY: rawNode.transform.minY,
    maxX: rawNode.transform.maxX,
    maxY: rawNode.transform.maxY,
    identifier: rawNode.identifier
  };

  // Get all collisions but exlcude all nodes that only "touch" our current one.
  let collisions = tree.fuzzySearchWithoutBorder(item);
  // let collisions = tree.search(transform)

  let intersectingNodes = [];
  let current_nodes = [item];
  let finished_nodes = [];
  // simulatePhysics(dispatch, getState, tree, item)
  // console.log("tree: ", tree.tree.data.children);
  // console.log("nodes: ", getState().specification.nodes.byIdentifier);

  // Sort the collisions according to their size from big to small.
  collisions.sort((a, b) => {
    // Collisions are always real bigger or real smaller and bigger always enclose
    // smaller ones, so it is enough to check minX
    if (a.minX < b.minX) {
      return -1;
    } else {
      return 1;
    }
  });
  collisions = collisions.filter(i => i.identifier !== rawNode.identifier)
  // console.log("collisions: ", collisions);

  // Iterate over the list. The last element that is bigger than the current node is its parent.
  // All elements that are smaller and have the new parent as its parent are new children.
  const resolveFoundParent = parentIdentifier => {
    // todo we only need the identifier
    rawNode.parent = getState().specification.nodes.byIdentifier[
      parentIdentifier
    ].identifier;

    // todo update parentCandidate
  };
  let parentCandidate = undefined;
  let firstChildCandidateReached = false;
  for (let index = 0; index < collisions.length; index++) {
    const element = collisions[index];
    if (element.identifier === rawNode.identifier)
      continue;

    if (validateSpatialRelationBetweenTransforms(item, element) === false) {
      console.log("failed to validate spacial ", element)
      continue;
    }
    if (intersectingNodes.includes(element.identifier)) {
      console.log("intersecting ", element)
      continue;
    }

    if (element.minX < item.minX) {
      parentCandidate = element;
      // console.log("< ", element.minX, item.minX)
      // if (index === collisions.length - 1) {
        resolveFoundParent(parentCandidate.identifier);
      // }
    } else {
      // console.log(">= ", element.minX, item.minX)
      if (firstChildCandidateReached) {
        if (parentCandidate) {
          resolveFoundParent(parentCandidate.identifier);
        } else {
          // The root is the parent.
          rawNode.parent = "root";
        }
        firstChildCandidateReached = true;
      }

      // We have a new parent. We can reassign all children that are in the list
      // of collisions and that are children of the new parent.
      // console.log("new parent ", element.identifier, " for ", rawNode.identifier)
      // console.log(getState().specification.nodes.byIdentifier)
      const elementNode = getState().specification.nodes.byIdentifier[
        element.identifier
      ];
      if (
        elementNode.parent === rawNode.parent ||
        elementNode.parent === undefined
      ) {
        rawNode.children.push(elementNode.identifier);
        // todo update element
      }
    }
  }


  // console.log("remove ", rawNode)
  dispatch({
    type: REMOVE_NODE,
    node: rawNode
  });
  // console.log("removed: ", getState().specification.nodes.byIdentifier);
  dispatch({
    type: ADD_NODE,
    node: rawNode
  });
  // console.log("add: ", getState().specification.nodes.byIdentifier);
}

export function moveNodes() {
  return function (dispatch, getState, tree) {
    simulatePhysics(dispatch, getState, tree)
  };
}

export function addNode(node) {
  return function (dispatch, getState, tree) {
    let rawNode = JSON.parse(JSON.stringify(node));

    // Create the spatial tree structure.
    const item = {
      minX: rawNode.transform.minX,
      minY: rawNode.transform.minY,
      maxX: rawNode.transform.maxX,
      maxY: rawNode.transform.maxY,
      identifier: rawNode.identifier
    };

    dispatch({
      type: ADD_NODE,
      node: rawNode
    });

    // Insert a spatial representative of the current tree into the spatial tree structure.
    // tree.insert(item);
    // console.log(getState().specification);
    tree.rehydrate(getState().specification);

    updateRelationships(item.identifier, tree, getState, dispatch);

    simulatePhysics(dispatch, getState, tree)
  };
}

export function removeNode(nodeIdentifier) {
  return function (dispatch, getState, tree) {
    const state = getState();
    const node = state.specification.nodes.byIdentifier[nodeIdentifier];
    // Also remove all connected edges:
    for (let i = 0; i < node.transitions.length; i++) {
      const element = node.transitions[i];

      dispatch(removeEdge(element));
    }

    tree.removeWithIdentifier(node.identifier);

    dispatch({
      type: REMOVE_NODE,
      node
    });

    tree.rehydrate(getState().specification);

    for(let child of node.children){
      // let childNode = {...state.specification.nodes.byIdentifier[child]}
      // childNode.parent = ""
      // dispatch({
      //   type: UPDATE_NODE,
      //   node: childNode
      // })
      updateRelationships(child, tree, getState, dispatch)
    }
    if (node.parent !== "") {
      // let parentNode = {...state.specification.nodes.byIdentifier[node.parent]}
      // let parentNode = JSON.parse(JSON.stringify(getState().specification.nodes.byIdentifier[node.parent]));
      // parentNode.children.remove(node.identifier)
      // dispatch({
      //   type: UPDATE_NODE,
      //   node: parentNode
      // })
      updateRelationships(node.parent, tree, getState, dispatch)
    }
  };
}

export function updateNode(node) {
  // todo check if node with id is present.

  return {
    type: UPDATE_NODE,
    node
  };
}

export function setNodeName(nodeIdentifier, name) {
  return {
    type: SET_NODE_NAME,
    nodeIdentifier,
    name
  };
}

export function setNodeSmachAction(
  nodeIdentifier,
  actionIdentifier,
  actionParameters
) {
  return {
    type: SET_NODE_SMACH_ACTION,
    nodeIdentifier,
    actionIdentifier,
    actionParameters
  };
}

export function addEdge(edge) {
  return function (dispatch) {
    dispatch({
      type: ADD_EDGE,
      edge: edge
    });
  };
}

export function removeEdge(edgeIdentifier) {
  return function (dispatch, getState) {
    // todo check if node with id is present.
    const state = getState();
    const edge = state.specification.edges.byIdentifier[edgeIdentifier];

    dispatch({
      type: REMOVE_EDGE,
      edge: edge
    });
  };
}

export function updateEdge(edge) {
  // todo check if node with id is present.

  return function (dispatch) {
    dispatch({
      type: UPDATE_EDGE,
      edge: edge
    });
  };
}

export function setEdgeSmachOutcome(edgeIdentifier, outcome) {
  return {
    type: SET_EDGE_SMACH_OUTCOME,
    edgeIdentifier,
    outcome
  };
}

export function setSelection(nodes) {
  return function (dispatch) {
    dispatch({
      type: SET_SELECTION,
      nodes: nodes
    });
  }
}

export function addToSelection(nodes) {
  return function (dispatch) {
    dispatch({
      type: ADD_TO_SELECTION,
      nodes: nodes
    });
  }
}

export function removeFromSelection(nodes) {
  return function (dispatch) {
    dispatch({
      type: REMOVE_FROM_SELECTION,
      nodes: nodes
    });
  }
}
// export function removeNode(nodeIdentifier) {
//   return function(dispatch, getState, tree) {
//     const state = getState();
//     const node = state.specification.nodes.byIdentifier[nodeIdentifier];
//     // Also remove all connected edges:
//     for (let i = 0; i < node.transitions.length; i++) {
//       const element = node.transitions[i];

//       dispatch(removeEdge(element));
//     }

//     tree.removeWithIdentifier(node.identifier);

//     dispatch({
//       type: REMOVE_NODE,
//       node
//     });
//   };
// }

// export function moveNodeDown(nodeIdentifier) {
//   return function (dispatch, getState, tree) {
//     const state = getState();
//     const node = state.specification.nodes.byIdentifier[nodeIdentifier];
//     let movedNode = { ...node, transform: { ...node.transform, maxY: node.transform.maxY + 1, minY: node.transform.minY + 1 } }

//     dispatch({
//       type: MOVE_NODE_DOWN,
//       node: movedNode
//     })
//   }
// }

export function moveNodeTransform(nodeIdentifier, transform) {
  return function (dispatch, getState, tree) {
    dispatch({
      type: MOVE_NODE_TRANSFORM,
      identifier: nodeIdentifier,
      transform: transform
    })
  }
}