export function getSpaceRect(state) {
  return state.space.rect;
}

export function getSpaceOffset(state) {
  return state.space.offset;
}

export function getTemporalSpaceOffset(state) {
  return state.space.temporalOffset;
}

export function getPPU(state) {
  return state.space.ppu;
}