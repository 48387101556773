import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { Space } from "space/components/space.jsx";
import { ActionMirror } from "./../../action-mirror/components/action-mirror.jsx";
import { getCurrentWorkflowModule, getPPU } from "selectors";
import { setCurrentWorkflowModule } from "./../workflow-module-selection-duck.js";
import { useParentWindowInterfaceCommunication } from "./../../parent-window-interface/parent-window-interface-communication-module.js";
import { zLayers } from "layout";

const StyledWorkflowModuleSelection = styled.div`
  background-color: #eee;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: ${`[row-top-start] 50px [row-top-end row-content-start] auto [row-content-end]`};
  grid-template-areas:
    "top"
    "content";
  justify-items: center;
  min-height: 0;
  min-width: 0;
  overflow: hidden;
`;

const StyledButtonArrayWrapper = styled.div`
  grid-area: top;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledWorkflowModuleIcons = styled.div`
  width: 200px;
  height: 35px;
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  background-color: #aaa;
  cursor: pointer;
  z-index: ${zLayers.gui + 10};
  &:hover {
    background-color: #bbb;
  }
  &.active {
    background-color: orange;
  }
`;

const StyledContentWrapper = styled.div`
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 3;
  width: 100%;
  height: 100%;
`;

const StyledBackground = styled.div`
  grid-area: top;
  width: 100%;
  height: 100%;
  background-color: #ccc;
  border-right: 0px solid;
  border-color: #555;
  box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.25);
`;

export function WorkflowModuleSelection() {
  const dispatch = useDispatch();
  const currentWorkflowModule = useSelector(getCurrentWorkflowModule);
  const ppu = useSelector(getPPU);

  const selectWorkflowModule = identifier => {
    dispatch(setCurrentWorkflowModule(identifier));
  };

  useParentWindowInterfaceCommunication(dispatch);

  let currentContent;
  switch (currentWorkflowModule) {
    case "STATE_MACHINE_MODULE":
      currentContent = <Space />;
      break;

    case "PYTHON_INTERFACE_MODULE":
      currentContent = <ActionMirror />;
      break;

    default:
      break;
  }

  return (
    <StyledWorkflowModuleSelection>
      <StyledBackground />
      <StyledButtonArrayWrapper>
        <StyledWorkflowModuleIcons
          key={"PYTHON_INTERFACE_MODULE"}
          index={1}
          className={
            "PYTHON_INTERFACE_MODULE" === currentWorkflowModule ? "active" : ""
          }
          onClick={() => selectWorkflowModule("PYTHON_INTERFACE_MODULE")}
        >
          Python Interface
        </StyledWorkflowModuleIcons>
        <StyledWorkflowModuleIcons
          key={"STATE_MACHINE_MODULE"}
          index={2}
          className={
            "STATE_MACHINE_MODULE" === currentWorkflowModule ? "active" : ""
          }
          onClick={() => selectWorkflowModule("STATE_MACHINE_MODULE")}
        >
          Behavior Specification
        </StyledWorkflowModuleIcons>
      </StyledButtonArrayWrapper>

      <StyledContentWrapper pixelsPerUnit={ppu}>{currentContent}</StyledContentWrapper>
    </StyledWorkflowModuleSelection>
  );
}
