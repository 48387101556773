export function getNodeSpecification(state, identifier) {
  return state.specification.nodes.byIdentifier[identifier];
}

export function getNodeSmachAction(state, identifier) {
  return state.specification.nodes.byIdentifier[identifier].smachAction;
}

export function getNodes(state) {
  return state.specification.nodes;
}

export function getEdges(state) {
  return state.specification.edges;
}

export function getSpecification(state) {
  return state.specification;
}

export function getSelection(state) {
  return state.specification.nodes.selected;
}

export function getNodeDistance(state) {
  return state.specification.nodes.nodeDistance;
}
