import React, { useContext, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NodeGhost } from "node/components/node-ghost.jsx";
import { getSpaceRect, getSpecification } from "selectors.js";
import {
  inputContext,
  useDrawGestureEvents,
  useInput,
  useGridPointerPosition
} from "input";
import { useTransformDrawing } from "./../helpers/transform-drawing-module.js";
import { addNode, removeNode, addEdge, saveHistory } from "specification";
import {
  extractRelevantEdges,
  createNodeTemplate
} from "specification/specification-helpers.js";

export function ResizeNodeTool(props) {
  const ic = useContext(inputContext);
  const dispatch = useDispatch();
  const spaceRect = useSelector(getSpaceRect);
  const specification = useSelector(getSpecification);
  const gridPointerPosition = useGridPointerPosition(ic.layer);

  const initialGestureState = {
    isValid: false,
    isActive: false,
    transform: {
      minX: 0,
      maxX: 0,
      minY: 0,
      maxY: 0
    }
  };
  const [gesture, setGesture] = useState(initialGestureState);

  const initialExtraction = {
    node: {},
    edges: []
  };
  const extraction = useRef(initialExtraction);

  const {
    startTransformDrawingGesture,
    updateTransformDrawingGesture,
    completeTransformDrawingGesture,
    cancelTransformDrawingGesture,
    transformDrawingGestureIsActive
  } = useTransformDrawing();

  useInput(
    ic.layer,
    "RESIZE_HANDLE_DOWN",
    event => {
      // get the transform of the current node.
      const currentTransformInGridPositions = {
        ...event.detail.nodeSpecification.transform,
        maxX: event.detail.nodeSpecification.transform.maxX - 1,
        maxY: event.detail.nodeSpecification.transform.maxY - 1
      };
      let anchors = [];
      switch (event.detail.direction) {
        case "top-left":
          anchors.push({
            x: currentTransformInGridPositions.maxX,
            y: currentTransformInGridPositions.maxY
          });
          break;

        case "top-right":
          anchors.push({
            x: currentTransformInGridPositions.minX,
            y: currentTransformInGridPositions.maxY
          });
          break;

        case "bottom-left":
          anchors.push({
            x: currentTransformInGridPositions.maxX,
            y: currentTransformInGridPositions.minY
          });
          break;

        case "bottom-right":
          anchors.push({
            x: currentTransformInGridPositions.minX,
            y: currentTransformInGridPositions.minY
          });
          break;

        case "top":
          anchors.push({
            x: currentTransformInGridPositions.maxX,
            y: currentTransformInGridPositions.maxY
          });
          anchors.push({
            x: currentTransformInGridPositions.minX,
            y: currentTransformInGridPositions.maxY
          });
          break;

        case "bottom":
          anchors.push({
            x: currentTransformInGridPositions.maxX,
            y: currentTransformInGridPositions.minY
          });
          anchors.push({
            x: currentTransformInGridPositions.minX,
            y: currentTransformInGridPositions.minY
          });
          break;

        case "left":
          anchors.push({
            x: currentTransformInGridPositions.maxX,
            y: currentTransformInGridPositions.minY
          });
          anchors.push({
            x: currentTransformInGridPositions.maxX,
            y: currentTransformInGridPositions.maxY
          });
          break;

        case "right":
          anchors.push({
            x: currentTransformInGridPositions.minX,
            y: currentTransformInGridPositions.minY
          });
          anchors.push({
            x: currentTransformInGridPositions.minX,
            y: currentTransformInGridPositions.maxY
          });
          break;

        default:
          break;
      }

      extraction.current = {
        node: createNodeTemplate(event.detail.nodeSpecification),
        edges: extractRelevantEdges(
          specification,
          event.detail.nodeSpecification.identifier
        )
      };
      dispatch(saveHistory());
      dispatch(removeNode(extraction.current.node.identifier));

      const transformDrawingGesture = startTransformDrawingGesture(
        gridPointerPosition.current,
        anchors
      );

      // Set state from gesture current
      setGesture(prev => {
        return {
          ...initialGestureState,
          isActive: true,
          isValid: transformDrawingGesture.isValid,
          transform: transformDrawingGesture.result
        };
      });
    },
    [specification]
  );

  useDrawGestureEvents(
    ic.layer,
    (event, context) => {
      // Start -> Resizing is triggered by speical resize handle events.
    },
    (event, context) => {
      // Update
      if (transformDrawingGestureIsActive()) {
        const transformDrawingGesture = updateTransformDrawingGesture(
          context.latest.gridPointerPosition
        );

        // Set state from gesture current
        setGesture(prev => {
          return {
            ...prev,
            isActive: transformDrawingGesture.isActive,
            isValid: transformDrawingGesture.isValid,
            transform: transformDrawingGesture.result
          };
        });
      }
    },
    (event, context) => {
      //complete
      
      if (transformDrawingGestureIsActive()) {
        const transformDrawingGesture = completeTransformDrawingGesture(
          context
        );

        let newNode = {
          ...extraction.current.node
        };

        // If the gesture is valid we use the new transform.
        if (transformDrawingGesture.isValid) {
          newNode.transform = transformDrawingGesture.result;
        }
        

        dispatch(addNode(newNode));
        for (let i = 0; i < extraction.current.edges.length; i++) {
          const edge = extraction.current.edges[i];
          dispatch(addEdge(edge));
        }

        // Finished -> reset local gesture.
        setGesture(prev => {
          return {
            ...initialGestureState
          };
        });
      }
    },
    () => {
      // Cancel.
      if (transformDrawingGestureIsActive()) {
        cancelTransformDrawingGesture();
        dispatch(addNode(extraction.current.node));
        for (let i = 0; i < extraction.current.edges.length; i++) {
          const edge = extraction.current.edges[i];
          dispatch(addEdge(edge));
        }
        // Finished -> reset local gesture.
        setGesture(prev => {
          return {
            ...initialGestureState
          };
        });
      }
    }
  );

  return (
    <NodeGhost
      show={gesture.isActive}
      transform={gesture.transform}
      isValid={gesture.isValid}
      anchor={spaceRect}
    ></NodeGhost>
  );
}
