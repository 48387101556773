import React from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { zLayers, getPixelsPerUnit } from "layout";
import { setNodeSmachAction } from "specification/";
import { useSelector } from "react-redux";
import { getPPU } from "selectors";

const StyledSmachAction = styled.div.attrs(props => {
  const pixelsPerUnit = props.pixelsPerUnit;
  
  return {
    pixelsPerUnit
  };
})`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto;
  grid-row-gap: ${props => props.pixelsPerUnit / 8}px;
  grid-template-areas:
    "first-line"
    "second-line";
  z-index: ${zLayers.gui};
`;

const StyledInput = styled.input`
  position: relative;
  grid-area: row-top-start / column-center-start / row-top-end /
    column-right-end;
  min-width: 0;
  overflow: hidden;
  place-self: stretch stretch;
  z-index: ${zLayers.gui};
`;

const ActionIdentifierInput = styled(StyledInput).attrs(props => {
  const pixelsPerUnit = props.pixelsPerUnit;

  return {
    pixelsPerUnit
  };
})`
  grid-area: first-line;
  margin-top: ${props => props.pixelsPerUnit / 8}px;
  margin-bottom: ${props => props.pixelsPerUnit / 8}px;
`;

const ActionParametersInput = styled(StyledInput).attrs(props => {
  const pixelsPerUnit = props.pixelsPerUnit;

  return {
    pixelsPerUnit
  };
})`
  grid-area: second-line;
  margin-top: ${props => props.pixelsPerUnit / 8}px;
  margin-bottom: ${props => props.pixelsPerUnit / 8}px;
`;

export function SmachAction(props) {
  const dispatch = useDispatch();
  const identifier = props.identifier;
  const action = props.action;
  const ppu = useSelector(getPPU);

  const handleActionIdentifierChange = event => {
    dispatch(
      setNodeSmachAction(identifier, event.target.value, action.parameters)
    );
  };
  const handleActionParametersChange = event => {
    dispatch(
      setNodeSmachAction(identifier, action.identifier, event.target.value)
    );
  };

  return (
    <StyledSmachAction pixelsPerUnit={ppu}>
      <ActionIdentifierInput
        pixelsPerUnit={ppu}
        type="text"
        value={action.identifier}
        placeholder="Action name"
        onChange={handleActionIdentifierChange}
      />
      <ActionParametersInput
        pixelsPerUnit={ppu}
        type="text"
        value={action.parameters}
        placeholder="Action parameters"
        onChange={handleActionParametersChange}
      />
    </StyledSmachAction>
  );
}
