import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { getNodes, getEdges, getSpaceOffset } from "selectors";
import { getPixelsPerUnit, zLayers } from "layout";

const EdgeCanvasContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const CanvasWindow = styled.div.attrs(props => ({
  style: {
    visibility: props.show || props.show === undefined ? "visible" : "hidden",
    transform: `translate(${props.offset.x}px, ${props.offset.y}px)`
  }
}))`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: ${zLayers.edges + 20};
`;

export function EdgeCanvas(props) {
  const nodes = useSelector(getNodes);
  const edges = useSelector(getEdges);
  const spaceOffset = useSelector(getSpaceOffset);

  return (
    <EdgeCanvasContainer>
      <CanvasWindow offset={props.offset} show={props.show}>
      </CanvasWindow>
    </EdgeCanvasContainer>
  );
}
