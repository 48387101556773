// todo: include light, (lighter) and dark (darker) variations of colors (see https://github.com/OfficeDev/office-ui-fabric-react/blob/master/packages/styling/src/interfaces/IPalette.ts)

import { Nodelike } from "base/components";
import styled from "styled-components";

// todo: do we need neutral colors?
export const palette = {
  themePrimary: "#222222",
  themeSecondary: "#222222",
  themeTertiary: "#222222",

  themeDarkest: "#222222",
  themeDarker: "#444444",
  themeDark: "#555555",
  themeMiddle: "#666666",
  themeLight: "#888888",
  themeLighter: "#AAAAAA",
  themeLightest: "#CCCCCC",

  themeDarkAlt: "#222222",
  themeLighterAlt: "#222222",

  black: "#222222",
  white: "#222222",

  yellow: "#FFD866",
  orange: "#FC9F72",
  red: "#FF6188",
  magenta: "#000000",
  purple: "#AA9CF0",
  blue: "#78DCE8",
  teal: "#000000",
  green: "#A9DC76",
  negativeBorderColor: "#FF6188",
  neutralBorderColor: "#78DCE8",
  hasParentBackgroundColor: "#a6eaed",
  neutralBackgroundColor: "#d6eaed",
  negativeBackgroundColor: "#f2d2da",
  isSelectedBackgroundColor: "#118b95",
  isSearchTarget: "#115111",
  isSearchTargetFade : "#c0f2c0",
};

function getBorderColorForMood(mood) {
  switch (mood) {
    case "negative":
      return palette.negativeBorderColor;
    case "search-target":
      return palette.isSearchTargetBorderColor;
    default:
      return palette.neutralBorderColor;
  }
}

function lerpColor(a, b, amount) { 

  var ah = +a.replace('#', '0x'),
      ar = ah >> 16, ag = ah >> 8 & 0xff, ab = ah & 0xff,
      bh = +b.replace('#', '0x'),
      br = bh >> 16, bg = bh >> 8 & 0xff, bb = bh & 0xff,
      rr = ar + amount * (br - ar),
      rg = ag + amount * (bg - ag),
      rb = ab + amount * (bb - ab);

  return '#' + ((1 << 24) + (rr << 16) + (rg << 8) + rb | 0).toString(16).slice(1);
}

function getBackgroundColorForDistance(distance){
  if (distance > 6)
    return palette.isSearchTargetFade;
  return lerpColor(palette.isSearchTarget, palette.isSearchTargetFade, distance/6);
}

function getBackgroundColorForMood(mood) {
  switch (mood) {
    case "negative":
      return palette.negativeBackgroundColor;
    // case "search-target":
    //   return palette.isSearchTarget;
    default:
      return palette.neutralBackgroundColor;
  }
}

export const StyledNodelike = styled(Nodelike).attrs(props => ({
  borderColor: getBorderColorForMood(props.mood),
  backgroundColor:
    // props.selected ? palette.isSelectedBackgroundColor :
    props.distance !== undefined ? getBackgroundColorForDistance(props.distance) :
    props.specification.parent !== "" ? palette.hasParentBackgroundColor :
    getBackgroundColorForMood(props.mood),
  thickness: props.mood === "search-target" ? 4 : 2
}))`
  box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.25);
  ${({ borderColor, backgroundColor, thickness }) =>
    `
        background-color: ${backgroundColor};
        border: ${thickness}px solid;
        border-color: ${borderColor};
  `}
  ${({ hovered, borderColor, backgroundColor, thickness }) =>
    hovered &&
    `
        background-color: ${backgroundColor};
        border: ${thickness}px solid;
        border-color: ${borderColor};
  `}
`;
