import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { Node } from "./../../node/components";
import { Grid } from "./../../base/components";
import { DiscretePointer } from "./../../pointers/components";
import { Toolbar } from "toolbar/components/index.js";
import { Toolbox } from "toolbox/components/index.js";
import { NaiveEdgeCanvas } from "edges/naive-edge-canvas/naive-edge-canvas";
import { EdgeCanvas } from "edges/edge-canvas/edge-canvas";
import {
  inputContext,
  useDrawGestureDetector,
  useHoveredTarget,
  usePointerPositionDetector
} from "input";
import { updateSpaceRect } from "../";
import {
  getNodes,
  getSpaceOffset,
  getTemporalSpaceOffset,
  getPPU
} from "selectors";
import { Minimap } from "space/components/minimap";
import { WaypointArrows } from "space/components/wayfindingArrows";
import { setPPU, translateSpace } from "space/space-duck";
import { useContext } from "react";
import { moveNodes } from "specification";

let uuidv4 = require("uuid/v4");

const StyledSpaceWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

const StyledSpace = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: ${props => `translate(${props.offset.x}px, ${props.offset.y}px)`};
`;

let tempNodeTransforms = {};

export function Space() {
  const nodes = useSelector(getNodes);
  const spaceOffset = useSelector(getSpaceOffset);
  const temporalSpaceOffset = useSelector(getTemporalSpaceOffset);
  const moveViewportIsActive =
    temporalSpaceOffset.x !== 0 || temporalSpaceOffset.y !== 0;
  const ppu = useSelector(getPPU);

  const [spaceIdentifier] = useState(uuidv4());
  const [spaceRect, setSpaceRect] = useState({
    left: 0,
    top: 0,
    witdh: 0,
    height: 0
  });

  const inputEventLayer = `${spaceIdentifier}/input`;
  const drawGestureCallbacks = useDrawGestureDetector(inputEventLayer);

  const dispatch = useDispatch();
  let myRef = useRef();

  // Connect the input utilities to the space element:

  useEffect(() => {
    const handleResize = () => {
      const bodyRect = document.body.getBoundingClientRect();
      const elementRect = myRef.current.getBoundingClientRect();
      const temporalSpaceRect = {
        left: elementRect.left - bodyRect.left,
        top: elementRect.top - bodyRect.top,
        width: elementRect.width,
        height: elementRect.height
      };

      if (spaceRect !== temporalSpaceRect) {
        setSpaceRect(temporalSpaceRect);
        dispatch(updateSpaceRect(temporalSpaceRect));
      }
    };

    // Call once for initial space rect.
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  usePointerPositionDetector(inputEventLayer, spaceRect, spaceOffset);

  function updateZoom(event) {
    const zoomAmount = -event.deltaY / 50;
    const oldPPU = ppu;

    const clientX = event.clientX;
    const mousePositionXInGridPixels = clientX - spaceOffset.x;
    const mousePositionXInGrid = mousePositionXInGridPixels / oldPPU;

    const clientY = event.clientY;
    const mousePositionYInGridPixels = clientY - spaceOffset.y;
    const mousePositionYInGrid = mousePositionYInGridPixels / oldPPU;

    const newPPU = Math.max(10, ppu + zoomAmount);

    const newMousePositionXInGridPixels = mousePositionXInGrid * newPPU;
    const newMousePositionYInGridPixels = mousePositionYInGrid * newPPU;

    const changeX = newMousePositionXInGridPixels - mousePositionXInGridPixels;
    const changeY = newMousePositionYInGridPixels - mousePositionYInGridPixels;

    dispatch(setPPU(newPPU));
    dispatch(translateSpace({x: -changeX, y: -changeY}));
  }

  // const [intervalId, setIntervalId] = useState(0);
  // const [getSimulate, setSimulate] = useState(0);
  // if (intervalId === 0) {
  //   const newIntervalId = setInterval(() => {
  //     let start = performance.now()
  //     setSimulate(_ => {
  //       dispatch(moveNodes());
  //       return _;
  //     });
  //     let end = performance.now()
  //     // console.log("dispatch", (end - start), "ms")
  //   }, 16);
  //   setIntervalId(newIntervalId);
  // }

  const hover = useHoveredTarget(inputEventLayer);
  const hovered = hover.current.identifier === "";

  let nodeArray = [];

  nodes.identifiers.forEach(key => {
    
    nodeArray.push(
      <Node
        key={key}
        specification={nodes.byIdentifier[key]}
        anchor={spaceRect}
        tempNodeTransforms={tempNodeTransforms}
      ></Node>
    );
  });

  return (
    <StyledSpaceWrapper
      onMouseDown={event => {
        drawGestureCallbacks.mouseDownHandler(event);
      }}
      onMouseUp={event => {
        drawGestureCallbacks.mouseUpHandler(event);
      }}
      onMouseLeave={event => {
        drawGestureCallbacks.mouseLeaveHandler(event);
      }}
      onWheel={event => updateZoom(event)}
      ref={myRef}
    >
      <inputContext.Provider
        value={{
          layer: inputEventLayer
        }}
      >
        <StyledSpace
          offset={{
            x: spaceOffset.x + temporalSpaceOffset.x,
            y: spaceOffset.y + temporalSpaceOffset.y
          }}
        >
            <Grid
              offset={{
                x: -spaceOffset.x,
                y: -spaceOffset.y
              }}
              show={moveViewportIsActive === false && hovered}
            />

            {nodeArray}

          <DiscretePointer />

          <Toolbox />
          
          <NaiveEdgeCanvas
              spaceRect={spaceRect}
              offset={{
                x: -spaceOffset.x,
                y: -spaceOffset.y
              }}
              show={moveViewportIsActive === false}
              tempNodeTransforms={tempNodeTransforms}
          />

        </StyledSpace>
        <Minimap />
        <WaypointArrows />
        <Toolbar />
      </inputContext.Provider>
    </StyledSpaceWrapper>
  );
}

/*
          <NaiveEdgeCanvas
              spaceRect={spaceRect}
              offset={{
                x: -spaceOffset.x,
                y: -spaceOffset.y
              }}
              show={moveViewportIsActive === false}
          />
 */
