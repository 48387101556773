import { useRef } from "react";
import { tree } from "configure-store.js";
import { validateTransform, createTransform } from "./transform-helpers.js";

export function useTransformDrawing() {
  const initialGestureState = {
    isActive: false,
    isValid: false,
    start: {
      x: 0,
      y: 0
    },
    latest: {
      x: 0,
      y: 0
    },
    result: {
      minX: 0,
      maxX: 0,
      minY: 0,
      maxY: 0
    },
    anchors: []
  };
  const gesture = useRef(initialGestureState);

  const startTransformDrawingGesture = (gridPointerPosition, anchors = []) => {
    // Start
    const currentTransform = createTransform(
      gridPointerPosition,
      gridPointerPosition,
      anchors
    );
    const isValid = validateTransform(currentTransform, tree);

    gesture.current = {
      ...initialGestureState,
      isActive: true,
      start: gridPointerPosition,
      latest: gridPointerPosition,
      anchors: anchors,
      isValid: isValid,
      result: currentTransform
    };
    return gesture.current;
  };
  const updateTransformDrawingGesture = gridPointerPosition => {
    // Update
    if (gesture.current.isActive) {
      // Calcualte intermediate result -> this can vary between drawings
      const currentTransform = createTransform(
        gesture.current.start,
        gridPointerPosition,
        gesture.current.anchors
      );
      const isValid = validateTransform(currentTransform, tree);

      gesture.current = {
        ...gesture.current,
        latest: gridPointerPosition,
        isValid: isValid,
        result: currentTransform
      };
    }

    return gesture.current;
  };
  const completeTransformDrawingGesture = () => {
    let raw = {
      ...gesture.current,
      isActive: false
    };
    gesture.current = {
      ...initialGestureState
    };
    return raw;
  };
  const cancelTransformDrawingGesture = () => {
    let raw = {
      ...gesture.current,
      isActive: false
    };
    gesture.current = {
      ...initialGestureState
    };
    return raw;
  };
  const transformDrawingGestureIsActive = () => {
    return gesture.current.isActive;
  };
  const updateAnchors = anchors => {
    gesture.current.anchors = anchors;
  };
  return {
    startTransformDrawingGesture,
    updateTransformDrawingGesture,
    completeTransformDrawingGesture,
    cancelTransformDrawingGesture,
    gesture,
    transformDrawingGestureIsActive,
    updateAnchors
  };
}
