import RBush from "rbush";

export default class SpatialTree {
  constructor() {
    this.tree = new RBush();
  }

  removeWithIdentifier(identifier) {
    return this.tree.remove(
      {
        identifier: identifier
      },
      (a, b) => {
        return a.identifier === b.identifier;
      }
    );
  }

  remove(item) {
    return this.tree.remove(item);
  }

  insert(item) {
    return this.tree.insert(item);
  }

  clear() {
    return this.tree.clear();
  }

  load(items) {
    return this.tree.load(items);
  }

  all() {
    return this.tree.all();
  }

  search(specification) {
    return this.tree.search(specification);
  }

  fuzzySearchWithoutBorder(specification) {
    const borderWidth = 0.1;
    const tempSpecification = {
      minX: specification.minX + borderWidth,
      minY: specification.minY + borderWidth,
      maxX: specification.maxX - borderWidth,
      maxY: specification.maxY - borderWidth
    };

    return this.search(tempSpecification);
  }

  collides(specification) {
    return this.tree.collides(specification);
  }

  rehydrate(specification) {
    // Before rehydrating, clear the tree.
    this.clear();

    // Rehydrate the store with all nodes already present after store rehydration.
    const nodes = specification.nodes;

    for (let i = 0; i < nodes.identifiers.length; i++) {
      const element = nodes.byIdentifier[nodes.identifiers[i]];

      // Create the spatial tree structure.
      const item = {
        minX: element.transform.minX,
        minY: element.transform.minY,
        maxX: element.transform.maxX,
        maxY: element.transform.maxY,
        identifier: element.identifier
      };

      this.tree.insert(item);
    }
  }
}
