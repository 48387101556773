import { useEffect } from "react";
import { createEventSystem } from "event-system";

// ECreate the shared input event system.
const events = createEventSystem();

// Dispatchers:

export function dispatchInputEvent(layer, topic, data) {
  const inputEvent = new CustomEvent(topic, { detail: data });
  events(layer).dispatchEvent(inputEvent);
}

// Subscribers:

export function subscribeInputEvent(layer, topic, callback) {
  events(layer).addEventListener(topic, callback);
}

// Hooks:

export function useInput(layer, topic, callback, deps = ["no"]) {
  useEffect(() => {
    events(layer).addEventListener(topic, callback);

    return () => {
      events(layer).removeEventListener(topic, callback);
    };
  }, deps);
}
