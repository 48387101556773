import React, { useState, useEffect, useRef } from "react";
import { useSelector} from "react-redux";
import {
  getNodes,
  getSpaceOffset,
  getTemporalSpaceOffset,
  getSpaceRect
} from "selectors";

import { getPPU } from "selectors";
import styled from "styled-components";
import {useAnimation} from "toolbox/animation-tools"

export const StyledArrow = styled.span`
  &:hover {
    color: "#ff00ff";
  }
`;

function Box({ children, ...props }) {
  return <div {...props}>{children}</div>
}
export function WaypointArrows(props) {
  const ppu = useSelector(getPPU);

  //get all the nodes from the redux Tree
  const nodes = useSelector(getNodes);
  const spaceCenterRect = useSelector(getSpaceRect);
  let spaceCenterLocationPixel = {...useSelector(getSpaceOffset)};
  spaceCenterLocationPixel.x -= spaceCenterRect.width/2;
  spaceCenterLocationPixel.y -= spaceCenterRect.height/2;

  let [hoveredId, setHoveredId] = useState("")
  const animatePosition = useAnimation();


  if (isNaN(spaceCenterRect.width))
    return <div></div>;

  let nodeArray = [];
  const minViewportWidth = 500/5;
  const minViewportHeight = 300/5;

  const minimapWidth = 500;
  const minimapHeight = 300;
  const minimapAspectRatio = minimapWidth / minimapHeight;

  //for reach node in the tree, place it into the minimap and scale the transform based on the great
  //encapsulating node
  nodes.identifiers.forEach(key => {
    let node = nodes.byIdentifier[key];

    let x = (node.transform.minX + node.transform.maxX) * 0.5 + spaceCenterLocationPixel.x / ppu;
    let y = (node.transform.minY + node.transform.maxY) * 0.5 + spaceCenterLocationPixel.y / ppu;

    let xPos = x * ppu;
    let yPos = y * ppu;

    let targetOffsetX = -(node.transform.minX + node.transform.maxX) * 0.5 * ppu + spaceCenterRect.width/2;
    let targetOffsetY = -(node.transform.minY + node.transform.maxY) * 0.5 * ppu + spaceCenterRect.height/2;
    if (isNaN(spaceCenterLocationPixel.x) || isNaN(spaceCenterLocationPixel.y)) {
      return;
    }
    const cornerBufferX = 70;
    const cornerBufferY = 55;

    if(yPos > -spaceCenterRect.height/2
      && yPos < spaceCenterRect.height/2
      && xPos > -spaceCenterRect.width/2
      && xPos < spaceCenterRect.width/2
      )
      return; // break for each
    if (isNaN(xPos) || isNaN(yPos)) {
      console.log("?")
      return;
    }

    if(xPos >= spaceCenterRect.width/2 - cornerBufferX)
      xPos = spaceCenterRect.width/2 - cornerBufferX;

    if(xPos <= -spaceCenterRect.width/2 + cornerBufferX)
      xPos = -spaceCenterRect.width/2 + cornerBufferX;

    if(yPos >= spaceCenterRect.height/2 - cornerBufferY)
      yPos = spaceCenterRect.height/2 - cornerBufferY;

    if(yPos <= -spaceCenterRect.height/2 + cornerBufferY)
      yPos = -spaceCenterRect.height/2 + cornerBufferY;

    const length = Math.sqrt(x*x + y*y);
    if (length == 0)
      return;
    x /= length;
    y /= length;
    const angle = -Math.atan2(x,y) + Math.PI;
    nodeArray.push(
      <Box
        onClick={(event) => {animatePosition({x: targetOffsetX, y: targetOffsetY}); event.preventDefault(); event.stopPropagation();}}
        key={node.identifier}
        style={{
          position: "absolute",
          color: hoveredId === key ? '#014411' : '#000',
          fontSize: Math.min(5 + 1500 / length, 200),
          left: xPos + spaceCenterRect.width/2 - 25,
          top: yPos + spaceCenterRect.height/2 - 50,
          transform: `rotate(${angle}rad)`
        }}
        onMouseEnter={() => setHoveredId(key)}
        onMouseLeave={() => setHoveredId("")}
        >
      <StyledArrow>🢁</StyledArrow>
    </Box>
    );
  });

  return (
    <Box
      style={{
        height: "100%",
        width: "100%"
      }}>
      {nodeArray}
    </Box>
  )
}