import styled from "styled-components";
import { getPixelsPerUnit } from "layout";
import { getWidth, getHeight } from "transform-helpers.js";
import { DEFAULT_TRANSFORM } from "specification/constants";
import { useSelector } from "react-redux";
import { getPPU } from "selectors";

const calculateBorderMargin = function(pixelsPerUnit) {
  return pixelsPerUnit / 5;
};
const calculateBorderFrame = function(pixelsPerUnit, borderMargin) {
  return pixelsPerUnit - borderMargin;
};

export const Nodelike = styled.div`
  position: absolute;
  grid-area: content;
  width: 100%;
  height: 100%;
  border-style: solid;
  border-width: 2px;
  border-color: #555;
  border-radius: 7px;
  background-color: #ddd;
  pointer-events: none;
`;

export const NodelikeFrame = styled.div`
  position: absolute;
  grid-column: column-start / column-end;
  grid-row: row-start / row-end;
  width: 100%;
  height: 100%;
  border-style: solid;
  border-width: 2px;
  border-color: #555;
  border-radius: 12px;
  pointer-events: none;
`;

export const NodeLikeWrapper = styled.div.attrs(props => {
  const pixelsPerUnit = props.pixelsPerUnit;
  const borderMargin = calculateBorderMargin(pixelsPerUnit);
  const borderFrame = calculateBorderFrame(pixelsPerUnit, borderMargin);

  return {
    pixelsPerUnit,
    borderMargin,
    borderFrame,
    transform: props.transform || DEFAULT_TRANSFORM
  };
})`
  position: absolute;
  display: grid;
  top: ${props => props.transform.minY * props.pixelsPerUnit + "px"};
  left: ${props => props.transform.minX * props.pixelsPerUnit + "px"};
  width: ${props => getWidth(props.transform) * props.pixelsPerUnit + "px"};
  height: ${props => getHeight(props.transform) * props.pixelsPerUnit + "px"};
  grid-template-columns: ${props =>
    `[column-start]${props.borderMargin}px auto ${props.borderMargin}px[column-end]`};
  grid-template-rows: ${props =>
    `[row-start]${props.borderMargin}px auto ${props.borderMargin}px[row-end]`};
  grid-template-areas:
    "top-border     top-border      top-border      "
    "left-border    content         right-border    "
    "bottom-border  bottom-border   bottom-border   ";
`;

export const ContentWrapper = styled.div.attrs(props => {
  const pixelsPerUnit = props.pixelsPerUnit;
  const borderMargin = calculateBorderMargin(pixelsPerUnit);
  const borderFrame = calculateBorderFrame(pixelsPerUnit, borderMargin);

  return {
    borderFrame
  };
})`
  grid-area: content;
  display: grid;
  min-height: 0;
  min-width: 0;
  grid-template-columns: ${props =>
    `[column-left-start] ${props.borderFrame}px [column-left-end column-center-start] auto [column-center-end column-right-start] ${props.borderFrame}px [column-right-end]`};
  grid-template-rows: ${props =>
    `[row-top-start] ${props.borderFrame}px [row-top-end row-center-start] auto [row-center-end row-bottom-start] ${props.borderFrame}px [row-bottom-end]`};
  grid-template-areas:
    "top-left        top             top-right       "
    "left            middle          right           "
    "bottom-left     bottom          bottom-right    ";
`;
