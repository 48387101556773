import { Controlled as CodeMirror } from "react-codemirror2";
import { setActionCode } from "./../../specification/specification-duck.js";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

require("codemirror/lib/codemirror.css");
require("codemirror/theme/material.css");
require("codemirror/theme/neat.css");
require("codemirror/mode/xml/xml.js");
require("codemirror/mode/javascript/javascript.js");
require("codemirror/mode/python/python.js");

const StyledCodeMirrorWrapper = styled.div`
  height: 100%;
  width: 100%;
  padding: 20px;
`;

const StyledCodeMirror = styled(CodeMirror)`
  height: 100%;
  width: 100%;
`;

export function ActionMirror() {
  const dispatch = useDispatch();
  const code = useSelector(state => {
    return state.specification.actionCode;
  });
  let setCode = code => {
    dispatch(setActionCode(code));
  };

  return (
    <StyledCodeMirrorWrapper>
      <StyledCodeMirror
        value={code}
        options={{
          mode: "python",
          theme: "material",
          lineNumbers: true
        }}
        onBeforeChange={(editor, data, value) => {
          setCode(value);
        }}
        onChange={(editor, data, value) => {}}
      />
    </StyledCodeMirrorWrapper>
  );
}
