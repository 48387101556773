import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { inputContext, useInput, GRID_POINTER_POSITION } from "input";
import { getTemporalSpaceOffset } from "selectors";
import { zLayers, getPixelsPerUnit } from "layout";
import { getPPU } from "selectors";

const StyledDiscretePointer = styled.div.attrs(props => ({
  style: {
    width: props.unitSize + "px",
    height: props.unitSize + "px",
    transform: `translate(${props.pointerPosition.x * props.unitSize + "px"},
            ${props.pointerPosition.y * props.unitSize + "px"}
            )`,
    opacity: props.active ? "1.0" : "0.0",
    top: 0,
    left: 0
  }
}))`
  position: absolute;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;
  z-index: ${zLayers.pointer};
`;

export function DiscretePointer(props) {
  const ppu = useSelector(getPPU);
  const pixelsPerUnit = ppu;
  const spaceRect = useSelector(state => state.space.spaceRect);
  const temporalSpaceOffset = useSelector(getTemporalSpaceOffset);
  const moveViewportIsActive =
    temporalSpaceOffset.x !== 0 || temporalSpaceOffset.y !== 0;
  const POINTER_POSITION_QUEUE_SIZE = 5;
  const ic = useContext(inputContext);
  const [pointerPositionQueue, setPointerPositionQueue] = useState({
    queue: [],
    pointer: -1
  });

  useInput(
    ic.layer,
    GRID_POINTER_POSITION,
    event => {
      let raw = pointerPositionQueue;
      raw.pointer = (raw.pointer + 1) % POINTER_POSITION_QUEUE_SIZE;
      raw.queue[raw.pointer] = event.detail.gridPointerPosition;
      setPointerPositionQueue({ ...raw });
    },
    [pointerPositionQueue]
  );

  const circleStyle = {
    stroke: "#bbb",
    strokeWidth: "2px",
    fill: "none",
    zIndex: zLayers.pointer
  };

  let pointers = [];
  for (let i = 0; i < pointerPositionQueue.queue.length; i++) {
    const pointerPosition = pointerPositionQueue.queue[i];

    let pointerVisibility = true; // Input Todo useVisibility hook.

    pointers.push(
      <StyledDiscretePointer
        key={`discretePointer${i}`}
        active={
          pointerVisibility &&
          pointerPositionQueue.pointer === i &&
          moveViewportIsActive === false
        }
        origin={spaceRect}
        pointerPosition={pointerPosition}
        unitSize={pixelsPerUnit}
      >
        <svg width="20px" height="20px" style={{ zIndex: zLayers.pointer }}>
          <circle style={circleStyle} cx="10" cy="10" r="5" />
        </svg>
      </StyledDiscretePointer>
    );
  }

  return <div>{pointers}</div>;
}
