import React, { useContext, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getSpaceRect } from "selectors.js";
import { inputContext, useDrawGestureEvents } from "input";
import { EdgeGhost } from "edges/edge-ghost/edge-ghost.jsx";
import { createEdgeSpecification } from "specification/specification-helpers.js";
import { addEdge, saveHistory } from "specification";

export function DrawEdgeTool(props) {
  const dispatch = useDispatch();
  const spaceRect = useSelector(getSpaceRect);
  const ic = useContext(inputContext);

  const initialContextState = {
    isActive: false,
    isValid: false,
    start: {
      pointerPosition: {
        x: 0,
        y: 0
      },
      hoverTarget: {}
    },
    latest: {
      pointerPosition: {},
      hoverTarget: {}
    },
    result: {}
  };
  const gesture = useRef(initialContextState);

  useDrawGestureEvents(
    ic.layer,
    (event, context) => {
      // start
      gesture.current = {
        ...gesture.current,
        start: {
          ...context.start
        },
        latest: {
          ...context.latest
        },
        isValid: false,
        isActive: true
      };
    },
    (event, context) => {
      // update
      gesture.current = {
        ...gesture.current,
        latest: {
          ...context.latest
        },
        isValid:
          gesture.current.start.hoverTarget.type === "node"
      };
    },
    (event, context) => {
      // complete
      
      const fromNode = gesture.current.start.hoverTarget;
      const toNode = context.latest.hoverTarget;

      gesture.current = {
        ...gesture.current,
        result: createEdgeSpecification(fromNode, toNode),
        isValid:
          gesture.current.start.hoverTarget.type === "node" &&
          gesture.current.latest.hoverTarget.type === "node",
        isActive: false
      };

      if (gesture.current.isValid) {
        dispatch(saveHistory());
        dispatch(addEdge(gesture.current.result));
      }
    },
    () => {
      // cancel
      gesture.current = initialContextState;
    }
  );

  return (
    <EdgeGhost specification={gesture.current} anchor={spaceRect}></EdgeGhost>
  );
}
