import { useInput, dispatchInputEvent } from "./../input-core.js";
import { getPixelsPerUnit } from "layout";
import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { getPPU } from "selectors.js";

// Topics:

export const PIXEL_POINTER_POSITION = `PIXEL_POINTER_POSITION`;
export const GRID_POINTER_POSITION = `GRID_POINTER_POSITION`;

// Detector:

/**
 * A pointer position effect that updates the pointer position in the discrete
 * space coordinates on mouse move.
 * @param {*} dispatch The dispatch reference of the current context to the current
 * redux store in order to publish space actions.
 * @param {*} spaceRect The rect of the space as origin.
 */
export function usePointerPositionDetector(layer, spaceRect, spaceOffset) {
  // Get the body rect as reference in order to handle scrolled page positions.
  const [bodyRect] = useState(document.body.getBoundingClientRect());
  const latestEvent = useRef(undefined);
  const ppu = useSelector(getPPU);
  const pixelsPerUnit = ppu;

  const previousPixelPointerPosition = useRef({
    x: 0,
    y: 0
  });
  const previousGridPointerPosition = useRef({
    x: 0,
    y: 0
  });

  useEffect(() => {
    const handleMouseMove = event => {
      latestEvent.current = event;

      const pixelPointerPosition = {
        x: Math.floor(
          event.pageX - (spaceRect.left - bodyRect.left) - spaceOffset.x
        ),
        y: Math.floor(
          event.pageY - (spaceRect.top - bodyRect.top) - spaceOffset.y
        )
      };

      if (
        pixelPointerPosition.x !== previousPixelPointerPosition.current.x ||
        pixelPointerPosition.y !== previousPixelPointerPosition.current.y
      ) {
        const gridPointerPosition = {
          x: Math.floor(pixelPointerPosition.x / pixelsPerUnit),
          y: Math.floor(pixelPointerPosition.y / pixelsPerUnit)
        };

        // The pointer position has changed since the last iteration
        // -> Update the pointer position in the store
        dispatchPixelPointerPosition(
          layer,
          pixelPointerPosition,
          gridPointerPosition
        );

        previousPixelPointerPosition.current = pixelPointerPosition;

        if (
          gridPointerPosition.x !== previousGridPointerPosition.current.x ||
          gridPointerPosition.y !== previousGridPointerPosition.current.y
        ) {
          // The pointer position has changed since the last iteration
          // -> Update the pointer position in the store
          dispatchGridPointerPosition(
            layer,
            pixelPointerPosition,
            gridPointerPosition
          );

          previousGridPointerPosition.current = gridPointerPosition;
        }
      }
    };

    if (latestEvent.current) {
      handleMouseMove(latestEvent.current);
    }

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [spaceRect, spaceOffset, pixelsPerUnit, bodyRect,layer]);
}

// Dispatchers:

export function dispatchPixelPointerPosition(
  layer,
  pixelPointerPosition,
  gridPointerPosition
) {
  dispatchInputEvent(layer, PIXEL_POINTER_POSITION, {
    pixelPointerPosition,
    gridPointerPosition
  });
}

export function dispatchGridPointerPosition(
  layer,
  pixelPointerPosition,
  gridPointerPosition
) {
  dispatchInputEvent(layer, GRID_POINTER_POSITION, {
    pixelPointerPosition,
    gridPointerPosition
  });
}

// Hooks:

export function usePixelPointerPosition(layer) {
  const [pointerPosition, setPointerPosition] = useState({
    x: 0,
    y: 0
  });

  useInput(
    layer,
    PIXEL_POINTER_POSITION,
    event => {
      const newPointerPosition = event.detail.pixelPointerPosition;
      setPointerPosition(newPointerPosition);
    },
    ["pointerPosition"]
  );

  return pointerPosition;
}

export function usePixelPointerPositionEvent(layer, callback) {
  useInput(
    layer,
    PIXEL_POINTER_POSITION,
    event => {
      callback(event, event.detail);
    },
    ["i am many things"]
  );
}

export function useGridPointerPosition(layer) {
  const pointerPosition = useRef({
    x: 0,
    y: 0
  });

  useInput(
    layer,
    GRID_POINTER_POSITION,
    event => {
      const newPointerPosition = event.detail.gridPointerPosition;
      pointerPosition.current = newPointerPosition;
    },
    ["what"]
  );

  return pointerPosition;
}

export function useGridPointerPositionEvent(layer, callback) {
  useInput(
    layer,
    GRID_POINTER_POSITION,
    event => {
      callback(event, event.detail);
    },
    ["i am many things"]
  );
}
