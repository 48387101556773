import React, { useContext, useRef } from "react";
import { useDispatch } from "react-redux";
import { inputContext, useDrawGestureEvents } from "input";
import { getSnappedOffset } from "../helpers/move-viewport-helpers.js";
import { translateSpace, setTemporalSpaceOffset } from "space";
import { useSelector } from "react-redux";
import { getPPU } from "selectors";

export function MoveViewportTool(props) {
  const dispatch = useDispatch();
  const ic = useContext(inputContext);
  const ppu = useSelector(getPPU);

  const initialContextState = {
    isActive: false,
    isValid: false,
    start: {
      position: {
        x: 0,
        y: 0
      }
    },
    latest: {
      position: {
        x: 0,
        y: 0
      }
    },
    result: {
      position: {
        x: 0,
        y: 0
      }
    }
  };
  const gesture = useRef(initialContextState);
  
  useDrawGestureEvents(
    ic.layer,
    (event, context) => {
      // start
      gesture.current = {
        ...gesture.current,
        start: {
          position: context.start.pixelPointerPosition
        },
        latest: {
          position: context.start.pixelPointerPosition
        },
        isValid: false,
        isActive: true
      };
    },
    (event, context) => {
      // update
      gesture.current = {
        ...gesture.current,
        latest: {
          position: context.latest.pixelPointerPosition
        },
        isValid: true
      };

      if (gesture.current.isValid) {
        const offset = {
          x:
            gesture.current.latest.position.x -
            gesture.current.start.position.x,
          y:
            gesture.current.latest.position.y - gesture.current.start.position.y
        };
        dispatch(setTemporalSpaceOffset(offset));
      }
    },
    (event, context) => {
      // complete
      gesture.current = {
        ...gesture.current,
        latest: {
          position: context.latest.pixelPointerPosition
        },
        isValid: true,
        isActive: false
      };

      if (gesture.current.isValid) {
        const snappedOffset = getSnappedOffset({
          x:
            gesture.current.latest.position.x -
            gesture.current.start.position.x,
          y:
            gesture.current.latest.position.y - gesture.current.start.position.y
        }, ppu);
        dispatch(
          setTemporalSpaceOffset({
            x: 0,
            y: 0
          })
        );
        dispatch(translateSpace(snappedOffset));
      }
    },
    () => {
      // cancel
      gesture.current = initialContextState;
    }
  );

  return <div></div>;
}
