import React from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { zLayers, getPixelsPerUnit, getToolGridPixelSize } from "layout";
import { selectPrimaryTool } from "toolbox/toolbox-duck.js";
import { getSelectedPrimaryTool } from "selectors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCoffee,
  faHandPaper,
  faArrowsAlt,
  faExpand,
  faEraser,
  faUndo,
  faRedo,
  faProjectDiagram,
  faVectorSquare,
  faExpandAlt
} from "@fortawesome/free-solid-svg-icons";
import { tools } from "toolbox/tools/tools.js";
import { redo, undo } from "specification";

const StyledToolbar = styled.div.attrs(props => {
  const toolGridPixelSize = getToolGridPixelSize();

  return {
    toolGridPixelSize
  };
})`
  position: absolute;
  left: 0px;
  top: 0px;
  width: ${props => props.toolGridPixelSize}px;
  height: 100%;
  display: grid;
  grid-template-rows: repeat(20, ${props => props.toolGridPixelSize - 10}px);
  grid-template-columns: ${props => props.toolGridPixelSize - 10}px;
  grid-row-gap: 5px;
  opacity: 1;
  z-index: ${zLayers.gui};
  padding: 5px;
`;

const StyledBackground = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #ccc;
  border-right: 0px solid;
  border-color: #555;
  z-index: ${zLayers.gui};
  box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.25);
`;

const StyledToolIcons = styled.div.attrs(props => {
  return {
    index: props.index || 0,
  };
})`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: ${props => props.index + 1};
  grid-row-end: ${props => props.index + 2};
  background-color: #aaa;
  cursor: pointer;
  z-index: ${zLayers.gui + 10};
  &:hover {
    background-color: #bbb;
  }
  &.active {
    background-color: orange;
  }
`;

const icons = {
  faCoffee: faCoffee,
  faHandPaper: faHandPaper,
  faArrowsAlt: faArrowsAlt,
  faExpand: faExpand,
  faExpandAlt: faExpandAlt,
  faEraser: faEraser,
  faUndo: faUndo,
  faRedo: faRedo,
  faProjectDiagram: faProjectDiagram,
  faVectorSquare: faVectorSquare,

};

export function Toolbar(props) {
  const selectedTool = useSelector(getSelectedPrimaryTool);
  const dispatch = useDispatch();

  const selectTool = identifier => {
    dispatch(selectPrimaryTool(identifier));
  };

  let toolIconArray = [];
  let index = 0;
  tools.identifiers.forEach(key => {

    toolIconArray.push(
      <StyledToolIcons
        key={key}
        index={index}
        className={key === selectedTool.identifier ? "active" : ""}
        onClick={() => selectTool(key)}
        title={tools.byIdentifier[key].title}
      >
        <FontAwesomeIcon icon={icons[tools.byIdentifier[key].icon]} />
      </StyledToolIcons>
    );
    index++;
  });
  
  return (
    <StyledToolbar>
      <StyledBackground />
      {toolIconArray}
      <StyledToolIcons
        onClick={() => dispatch(undo())}
        index={tools.identifiers.length + 1}
        title={"Undo last action"}
      >
        <FontAwesomeIcon icon={icons.faUndo} />
      </StyledToolIcons>
      <StyledToolIcons
        onClick={() => dispatch(redo())}
        index={tools.identifiers.length + 2}
        title={"redo last undo"}
      >
        <FontAwesomeIcon icon={icons.faRedo} />
      </StyledToolIcons>
    </StyledToolbar>
  );
}
