import React, { useContext } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { inputContext, usePixelPointerPosition } from "input";
import { zLayers } from "layout";
import { NodelikeFrame, NodeLikeWrapper } from "base/components";
import { getNodes } from "selectors";


import { getPPU } from "selectors";
export const StyledMouseFrame = styled.div.attrs(props => ({
  style: {
    top: props.pointerPosition.y - 15,
    left: props.pointerPosition.x - 15
  }
}))`
  position: fixed;
  width: 20px;
  height: 20px;
  border-style: solid;
  border-width: 2px;
  border-color: #78dce8;
  border-radius: 999px;
  pointer-events: none;
  background-color: #78dce833;
  z-index: ${zLayers.gui + 1000};
`;

const StyledNodelikeFrame = styled(NodelikeFrame)`
  border-color: #78dce8;
`;

export function EdgeGhost(props) {
  const specification = props.specification;
  const anchor = props.anchor;
  const nodes = useSelector(getNodes);
  const ic = useContext(inputContext);
  const ppu = useSelector(getPPU);

  const pointerPosition = usePixelPointerPosition(ic.layer);

  let transform = undefined;
  if (specification.start?.hoverTarget?.type === "node") {
    transform =
      nodes.byIdentifier[specification.start.hoverTarget.identifier].transform;
  }

  return (
    <div className="mouseframewrapper">
      {specification.isActive && specification.isValid && (
        <StyledMouseFrame pointerPosition={pointerPosition} />
      )}
      {specification.isActive && specification.isValid && (
        <NodeLikeWrapper anchor={anchor} transform={transform} pixelsPerUnit={ppu}>
          <StyledNodelikeFrame />
        </NodeLikeWrapper>
      )}
    </div>
  );
}
