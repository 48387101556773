import React from "react";
import styled from "styled-components";
import { zLayers, getPixelsPerUnit } from "layout";
import { useSelector } from "react-redux";
import { getPPU } from "selectors";

const StyledGrid = styled.div.attrs(props => ({
  style: {
    opacity: props.show || props.show === undefined ? "1.0" : "0.0",
    transform: `translate(${props.offset.x}px, ${props.offset.y}px)`
  }
}))`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: ${zLayers.background + 20};
  transition: opacity 0.3s ease;
`;

export function Grid(props) {
  let circleStyle = {
    fill: "#999"
  };
  const ppu = useSelector(getPPU);
  const pixelsPerUnit = ppu;

  return (
    <StyledGrid
      show={props.show}
      className={props.className}
      offset={props.offset || { x: 0, y: 0 }}
    >
      <svg width="100%" height="100%">
        <defs>
          <pattern
            id="dots"
            x="0"
            y="0"
            width={pixelsPerUnit}
            height={pixelsPerUnit}
            patternUnits="userSpaceOnUse"
          >
            <circle
              style={circleStyle}
              cx={pixelsPerUnit / 2}
              cy={pixelsPerUnit / 2}
              r="1.5"
            />
          </pattern>
        </defs>
        <rect x="0" y="0" width="100%" height="100%" fill="url(#dots)" />
      </svg>
    </StyledGrid>
  );
}
