import React from "react";
import styled from "styled-components";
import { Nodelike, NodeLikeWrapper } from "base/components";
import { zLayers } from "layout";
import { useSelector } from "react-redux";
import { getPPU } from "selectors";

const backgroundOpacity = "33";
const validColor = "#78DCE8";
const invalidColor = "#FF6188";

const StyledNodeGhost = styled(Nodelike).attrs(props => ({
  style: {
    display: props.show ? "block" : "none",
    backgroundColor: props.valid
      ? validColor + backgroundOpacity
      : invalidColor + backgroundOpacity,
    borderColor: props.valid ? validColor : invalidColor,
    zIndex: zLayers.ghost
  }
}))`
  grid-area: content;
  pointer-events: none;
  opacity: 1;
  transition: all 0.3s ease-in-out;
`;

export function NodeGhost(props) {
  const transform = props.transform;
  const anchor = props.anchor;
  const ppu = useSelector(getPPU);

  return (
    <NodeLikeWrapper anchor={anchor} transform={transform} pixelsPerUnit={ppu}>
      <StyledNodeGhost
        anchor={anchor}
        transform={transform}
        show={props.show}
        valid={props.isValid}
      ></StyledNodeGhost>
    </NodeLikeWrapper>
  );
}
