import React, { useContext } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { zLayers } from "layout";
import { Input } from "base/components";
import { setEdgeSmachOutcome } from "specification";
import {
  inputContext,
  useHoveredTargetDetector,
  useHoveredTarget
} from "input";
import { useSelector } from "react-redux";
import { getPPU } from "selectors";

const StyledEdgeWrapper = styled.div.attrs(props => {
  const pixelsPerUnit = props.pixelsPerUnit;
  const labelHeight = pixelsPerUnit * 0.66;
  const labelWidth = pixelsPerUnit * 3;

  return {
    labelHeight,
    labelWidth,
    position: props.position || {
      x: 0,
      y: 0
    }
  };
})`
  position: absolute;
  z-index: ${zLayers.edges + 100};
  height: ${props => props.labelHeight}px;
  width: ${props => props.labelWidth}px;
  top: ${props => props.position.y - props.labelHeight / 2 + "px"};
  left: ${props => props.position.x - props.labelWidth / 2 + "px"};
`;

const neutralBorderColor = "#78DCE8";
const neutralBackgroundColor = "#d6eaed";
const negativeBorderColor = "#FF6188";
const negativeBackgroundColor = "#f2d2da";

const StyledName = styled(Input).attrs(props => {
  const pixelsPerUnit = props.pixelsPerUnit;

  return {
    pixelsPerUnit,
    hoverBorderColor:
      props.mood === "negative" ? negativeBorderColor : neutralBorderColor,
    hoverBackgroundColor:
      props.mood === "negative"
        ? negativeBackgroundColor
        : neutralBackgroundColor,
    hovered: props.hovered || false
  };
})`
  border-radius: 999px;
  margin: ${props => props.pixelsPerUnit / 8}px;
  width: 100%;
  height: 100%;
  min-width: 1em;
  overflow: hidden;
  place-self: stretch stretch;
  z-index: ${zLayers.gui};
  background: #ddd;
  ${({ hovered, hoverBorderColor, hoverBackgroundColor }) =>
    hovered &&
    `
        background-color: ${hoverBackgroundColor};
        border-color: ${hoverBorderColor};
  `}
`;

export function NaiveEdge(props) {
  const dispatch = useDispatch();
  const specification = props.specification;
  const ic = useContext(inputContext);
  const ppu = useSelector(getPPU);

  const handleChange = event => {
    dispatch(setEdgeSmachOutcome(specification.identifier, event.target.value));
  };

  const { mouseEnterHandler, mouseLeaveHandler } = useHoveredTargetDetector(
    ic.layer,
    props.specification.identifier,
    "edge"
  );

  const hover = useHoveredTarget(ic.layer);
  const hovered = hover.current.identifier === props.specification.identifier;

  return (
    <StyledEdgeWrapper
      className={hovered ? "hovered" : ""}
      position={props.position}
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
      pixelsPerUnit={ppu}
    >
      <StyledName
        pixelsPerUnit={ppu}
        type="text"
        value={specification.if.outcome}
        onChange={handleChange}
        hovered={props.selectedPrimaryTool?.hover.edge && hovered}
        mood={props.selectedPrimaryTool?.hover.mood}
      />
    </StyledEdgeWrapper>
  );
}
