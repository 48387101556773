import { not } from "helpers.js";

export const EventLayer = function() {
  // We create a DOM EventTarget object...
  let target = document.createTextNode(null);

  // ... and facade the EventTarget interfaces.
  this.addEventListener = target.addEventListener.bind(target);
  this.removeEventListener = target.removeEventListener.bind(target);
  this.dispatchEvent = target.dispatchEvent.bind(target);
};

export const createEventSystem = function() {
  let eventLayers = {
    byIdentifier: {},
    identifiers: []
  };

  return function(layerIdentifier) {
    if (not(eventLayers.identifiers.includes(layerIdentifier))) {
      // Register a new event layer for the specified identifier.
      eventLayers[layerIdentifier] = new EventLayer();
      eventLayers.identifiers.push(layerIdentifier);
    }

    return eventLayers[layerIdentifier];
  };
};
