import React from "react";
import styled from "styled-components";
import { zLayers } from "layout";
import { hasOwnProperty } from "helpers.js";
import { FiDisc } from "react-icons/fi";

const EntryPointsWrapper = styled.div`
  grid-area: row-center-start / column-left-start / row-center-end /
    column-left-end;
  min-width: 0;
  min-height: 0;
  overflow: hidden;
  place-self: stretch stretch;
  z-index: ${zLayers.nodeContent};
`;

const SquareContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 100%);
  grid-gap: 5px;
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
`;

const Square = styled.div`
  position: relative;
  box-sizing: border-box;

  &::before {
    content: "";
    display: block;
    padding-top: 100%;
  }

  & .content {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
`;

const StyledFiDisc = styled(FiDisc)`
  width: 100%;
  height: 100%;
`;

export function EntryPoints(props) {
  const defaultEntryPoint = hasOwnProperty(props.entryPoints, "default") ? (
    <Square>
      <div className="content">
        <StyledFiDisc />
      </div>
    </Square>
  ) : null;

  return (
    <EntryPointsWrapper>
      <SquareContainer>{defaultEntryPoint}</SquareContainer>
    </EntryPointsWrapper>
  );
}
