import React, { useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getSpaceOffset,
} from "selectors";
import { setSpaceOffset, setTemporalSpaceOffset } from "space/space-duck";


export function useAnimation() {

  const [intervalId, setIntervalId] = useState(0);
  const spaceOffset = useSelector(getSpaceOffset);
  const [currentSpaceOffset, setCurrentSpaceOffset] = useState({...useSelector(getSpaceOffset)});

  const dispatch = useDispatch();

  useEffect(() => {
    // console.log("new")
    dispatch(setSpaceOffset(currentSpaceOffset));
  }, [currentSpaceOffset, dispatch]);

  const [getClearInterval, setClearInterval] = useState(0);

  useEffect(() => {
    clearInterval(intervalId);
    setIntervalId(0);
  }, [getClearInterval]);

  if (isNaN(currentSpaceOffset.x) || isNaN(currentSpaceOffset.y))
    return () => {};

  return (snappedOffset) => {
    if (intervalId) {
      clearInterval(intervalId);
    }

    // console.log("snapped offset: ", snappedOffset, "space offset:", spaceOffset, "currentspaceoffset:", currentSpaceOffset);
    setCurrentSpaceOffset(spaceOffset);

    // #HACK: Set temporal space offset to a small value != 0 so that the grid gets hidden
    dispatch(setTemporalSpaceOffset({x: 0.1, y: 0}));

    const newIntervalId = setInterval(() => {
      console.log("minimap")
      // update coordinate
      // % approach rate t
      const t = 0.25;

      //take current coordinates, and lerp to target offset until pixel distance < 5
      //update screen on effect whenever the current space offset changes
      setCurrentSpaceOffset(currentSpaceOffset => {
        const dx = snappedOffset.x - currentSpaceOffset.x;
        const dy = snappedOffset.y - currentSpaceOffset.y;
        var returnValue = null;
        if (Math.sqrt(dx * dx + dy * dy) < 5) {
          // #HACK: Reset temporal space offset so that the grid gets shown again
          dispatch(setTemporalSpaceOffset({x: 0, y: 0}));
          setClearInterval(old => old + 1);
          returnValue = snappedOffset;
        } else {
          returnValue = {
            x: currentSpaceOffset.x * (1 - t) + snappedOffset.x * t,
            y: currentSpaceOffset.y * (1 - t) + snappedOffset.y * t,
          };
        }
        if (isNaN(returnValue.x) || isNaN(returnValue.y)){
          setClearInterval(old => old + 1);
          return currentSpaceOffset;
        }

        return returnValue;
      });
    }, 16);
    setIntervalId(newIntervalId);
  }
}
