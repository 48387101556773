import produce from "immer";
import { appIdentifier } from "app-constants.js";

const SELECT_PRIMARY_TOOL = `${appIdentifier}/node-drawing/SELECT_PRIMARY_TOOL`;
const CLEAR_PRIMARY_TOOL = `${appIdentifier}/node-drawing/CLEAR_PRIMARY_TOOL`;

const initialState = {
  selectedPrimaryTool: "drawNode"
};

// Reducer:
export default function reducer(state = initialState, action = {}) {
  return produce((draft, action) => {
    switch (action.type) {
      case SELECT_PRIMARY_TOOL:
        draft.selectedPrimaryTool = action.toolIdentfier;
        return;

      case CLEAR_PRIMARY_TOOL:
        draft.selectedPrimaryTool = "";
        return;

      default:
    }
  })(state, action);
}

// Actions:

export function selectPrimaryTool(toolIdentfier) {
  return function(dispatch, getState) {
    dispatch({
      type: SELECT_PRIMARY_TOOL,
      toolIdentfier
    });
  };
}

export function clearPrimaryTool() {
  return function(dispatch, getState) {
    dispatch({
      type: CLEAR_PRIMARY_TOOL
    });
  };
}
