import React, { useState, useEffect, useRef, useContext} from "react";
import { useSelector, useDispatch } from "react-redux";
import { tree } from "configure-store.js";
import {
  getNodes,
  getSpaceOffset,
  getSpaceRect
} from "selectors";
import { getHeight, getWidth } from "transform-helpers";
import { NodeLikeWrapper } from "base/components";
import { getPixelsPerUnit } from "layout";
import { translateSpace, setSpaceOffset } from "space/space-duck";
import { inputContext, useDrawGestureEvents, useHoveredTarget } from "input";
import { getPPU, getSelectedPrimaryTool, getSelection, getNodeDistance } from "selectors";
import {useAnimation} from "toolbox/animation-tools"
import { StyledNodelike } from "styling/color-palette";

function Box({ children, ...props }) {
  return <div {...props}>{children}</div>
}

//Function to span a node that encapsulates all of the nodes on the field
function getRectEnclosingNode(nodes) {
  let smallestMinX = 1000000;
  let biggestMaxX = -1000000;
  let smallestMinY = 1000000;
  let biggestMaxY = -1000000;

  //iterate through all the nodes and look for the extremes
  nodes.identifiers.forEach(key => {
    let node = nodes.byIdentifier[key];

    if (node.transform.minX < smallestMinX)
      smallestMinX = node.transform.minX
    if (node.transform.maxX > biggestMaxX)
      biggestMaxX = node.transform.maxX
    if (node.transform.minY < smallestMinY)
      smallestMinY = node.transform.minY
    if (node.transform.maxY > biggestMaxY)
      biggestMaxY = node.transform.maxY
  });
  return { minX: smallestMinX, maxX: biggestMaxX, minY: smallestMinY, maxY: biggestMaxY };
}

//Moves a rectangle
function translate(transform, x, y) {
  return {
    minX: transform.minX + x,
    maxX: transform.maxX + x,
    minY: transform.minY + y,
    maxY: transform.maxY + y,
  }
}

function expand(rect, x, y) {
  return {
    minX: rect.minX - x,
    maxX: rect.maxX + x,
    minY: rect.minY - y,
    maxY: rect.maxY + y,
  }
}

export function Minimap(props) {
  const dispatch = useDispatch();
  const ppu = useSelector(getPPU);

  //get all the nodes from the redux Tree
  const nodes = useSelector(getNodes);
  const screenSize = useSelector(getSpaceRect);
  let spaceCenterRect = {...useSelector(getSpaceRect)};
  spaceCenterRect.width /= ppu;
  spaceCenterRect.height /= ppu;

  let spaceCenterLocationPixel = {...useSelector(getSpaceOffset)};
  spaceCenterLocationPixel.x /= -ppu;
  spaceCenterLocationPixel.y /= -ppu;

  const ic = useContext(inputContext);
  const hover = useHoveredTarget(ic.layer);
  const selection = useSelector(getSelection);
  const nodeDistances = useSelector(getNodeDistance);
  const selectedPrimaryTool = useSelector(getSelectedPrimaryTool);

  // const hover = useHoveredTarget(ic.layer);
  // const hovered = hover.current.identifier === props.specification.identifier;

  let nodeArray = [];
  const minViewportWidth = 500/5;
  const minViewportHeight = 300/5;

  const minimapWidth = 500;
  const minimapHeight = 300;
  const minimapAspectRatio = minimapWidth / minimapHeight;

  let rectEnclosingNode = expand(getRectEnclosingNode(nodes), 10, 10);

  let width = getWidth(rectEnclosingNode);
  let height = getHeight(rectEnclosingNode);

  const animatePosition = useAnimation();
  // console.log(currentSpaceOffset);

  //set the minimal and maximal minimap size
  if (width < minViewportWidth) {
    rectEnclosingNode.minX -= (minViewportWidth - width) / 2;
    rectEnclosingNode.maxX += (minViewportWidth - width) / 2;
  }
  if (height < minViewportHeight) {

    rectEnclosingNode.minY -= (minViewportHeight - height) / 2;
    rectEnclosingNode.maxY += (minViewportHeight - height) / 2;
  }

  let aspectRatio = getWidth(rectEnclosingNode) / getHeight(rectEnclosingNode);

  if (aspectRatio < minimapAspectRatio){
    // Increase width
    let x = minimapAspectRatio * getHeight(rectEnclosingNode) - getWidth(rectEnclosingNode)
    rectEnclosingNode.minX -= x / 2;
    rectEnclosingNode.maxX += x / 2;
  } else {
    // Increase height
    let y = getWidth(rectEnclosingNode) / minimapAspectRatio - getHeight(rectEnclosingNode);
    rectEnclosingNode.minY -= y / 2;
    rectEnclosingNode.maxY += y / 2;
  }
  width = getWidth(rectEnclosingNode);
  height = getHeight(rectEnclosingNode);

  let pixelsPerUnit = minimapWidth / getWidth(rectEnclosingNode);

  function iterateNodes(callback) {
    nodes.identifiers.forEach(key => {
      let node = nodes.byIdentifier[key];
      callback(node);
    });
  }

  const [state, setState] = useState("uiae");
  function nodeHighlight(event){
    setState(event.nativeEvent.target.value)
  }

  
  //for reach node in the tree, place it into the minimap and scale the transform based on the great
  //encapsulating node
  iterateNodes((node) => {
    let backgroundColor = '#a6eaed';
    let borderColor = '#78DCE8';

    var mood = ""
    if (state !== "" && (node.name.includes(state) || node.identifier.includes(state))) {
      backgroundColor = '#118b95';
      mood = "search-target"
    }

    const selected = selection.includes(node.identifier);
    const hovered = hover.current.identifier === node.identifier;
    const nodeDistance = nodeDistances[node.identifier];

    let transform = translate(node.transform, -rectEnclosingNode.minX, -rectEnclosingNode.minY);
    nodeArray.push(
      <NodeLikeWrapper
        key={node.identifier}
        transform={transform}
        pixelsPerUnit={pixelsPerUnit}
      >
        <StyledNodelike
          hovered={selectedPrimaryTool?.hover.node && hovered}
          mood={hovered ? selectedPrimaryTool?.hover.mood ?? mood : mood}
          specification={node}
          selected={selected}
          distance={nodeDistance}
          style={{
            borderRadius: 4,
            color: '#eee',
            width: getWidth(node.transform) * pixelsPerUnit,
            height: getHeight(node.transform) * pixelsPerUnit,
          }}>
        </StyledNodelike>
      </NodeLikeWrapper>
    );
  });

  // It works!
  if (isNaN(spaceCenterRect.width))
    return <div></div>;

  if (isNaN(spaceCenterLocationPixel.x) || isNaN(spaceCenterLocationPixel.y))
    return <div></div>;

  let transform  = {
    minX: spaceCenterLocationPixel.x,
    maxX: spaceCenterLocationPixel.x + spaceCenterRect.width,
    minY: spaceCenterLocationPixel.y,
    maxY: spaceCenterLocationPixel.y + spaceCenterRect.height,
  };
  transform = translate(transform, -rectEnclosingNode.minX, -rectEnclosingNode.minY);

  // when clicked on the minimap, Snap camera to the spot on the real field
  function handleClick(pixelX, pixelY) {
    // Coordinate in the minimap (left top corner is (0, 0), bottom right is (500, 300))
    var x = pixelX - (screenSize.width - minimapWidth - 40);
    var y = pixelY - 40;

    //Offset to where the mouse is being clicked -> Position of the top left screen moves there + half the screensize
    let snappedOffset = {
      x: -((x / minimapWidth) * width + rectEnclosingNode.minX) * ppu + screenSize.width/2,
      y: -((y / minimapHeight) * height + rectEnclosingNode.minY) * ppu + screenSize.height/2
    };

    animatePosition(snappedOffset);
  }

  // const ic = useContext(inputContext);
  // useDrawGestureEvents(
  //   ic.layer,
  //   (event, context) => {
  //     console.log("start", context);
  //     handleClick(context.start.pixelPointerPosition.x, context.start.pixelPointerPosition.y);
  //   },
  //   (event, context) => {
  //     console.log("update", context);
  //   },
  //   (event, context) => {
  //     console.log("end", context);
  //   },
  //   (event, context) => {
  //     console.log("cancel", event, context);
  //   });


  return (
    <Box
      onClick={(event) => {handleClick(event.clientX, event.clientY); event.preventDefault(); event.stopPropagation();}}
      // onDrag={handleClick}
      style={{
        position: "absolute",
        right: "40px",
        top: "40px",
        backgroundColor: '#333',
        borderRadius: 4,
        color: '#eee',
        // minHeight: "25%",
        // width: "18%"
        height: minimapHeight,
        width: minimapWidth
        // overflow: "hidden"
      }}>

      <NodeLikeWrapper
        transform={transform}
        pixelsPerUnit={pixelsPerUnit}
      >
        <Box style={{
          // position: "absolute",
          borderRadius: 4,
          border: 'solid #e00',
          width: getWidth(transform) * pixelsPerUnit,
          height: getHeight(transform) * pixelsPerUnit,
        }}>
        </Box>
      </NodeLikeWrapper>
      {nodeArray}
      <input
      style={{margin : "5px"}}
      type="text"
      value={state.value}
      onChange={(event) => nodeHighlight(event)}/>
    </Box>

  )
}