import React from "react";
import { useSelector } from "react-redux";
import { DrawNodeTool } from "./../tools/components/draw-node-tool.jsx";
import { MoveViewportTool } from "./../tools/components/move-viewport-tool.jsx";
import { DrawEdgeTool } from "./../tools/components/draw-edge-tool.jsx";
import { EraseTool } from "./../tools/components/erase-tool.jsx";
import { ResizeNodeTool } from "./../tools/components/resize-node-tool.jsx";
import { MoveNodeTool } from "./../tools/components/move-node-tool.jsx";
// import { MoveNodeDownTool } from "toolbox/tools/components/move-node-down-tool.jsx";
import { getSelectedPrimaryTool } from "selectors.js";
import { undo } from "specification/specification-duck.js";
import { useDispatch } from "react-redux";

export function Toolbox(props) {
  const primaryTool = useSelector(getSelectedPrimaryTool);
  // const dispatch = useDispatch();

  let selectedToolComponent;
  switch (primaryTool.identifier) {
    case "moveViewport":
      selectedToolComponent = <MoveViewportTool />;
      break;

    case "drawNode":
      selectedToolComponent = <DrawNodeTool />;
      break;

    case "drawEdge":
      selectedToolComponent = <DrawEdgeTool />;
      break;

    case "erase":
      selectedToolComponent = <EraseTool />;
      break;

    case "resizeNode":
      selectedToolComponent = <ResizeNodeTool />;
      break;

    case "moveNode":
      selectedToolComponent = <MoveNodeTool />;
      break;

    // case "moveNodeDown":
    //   // dispatch(undo());
    //   selectedToolComponent = <MoveNodeDownTool />;
    //   break;

    default:
      break;
  }

  return <div>{selectedToolComponent}</div>;
}
