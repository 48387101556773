export function getWidth(transform) {
  return transform.maxX - transform.minX;
}

export function getHeight(transform) {
  return transform.maxY - transform.minY;
}

export function getXPosition(transform) {
  return transform.minX;
}

export function getYPosition(transform) {
  return transform.minY;
}

export function subtract(a, b) {
  return {
    minX: a.minX - b.minX,
    maxX: a.maxX - b.maxX,
    minY: a.minY - b.minY,
    maxY: a.maxY - b.maxY,
  }
}
export function add(a, b) {
  return {
    minX: a.minX + b.minX,
    maxX: a.maxX + b.maxX,
    minY: a.minY + b.minY,
    maxY: a.maxY + b.maxY,
  }
}


