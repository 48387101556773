import produce from "immer";
import { appIdentifier } from "app-constants.js";

// Actions:
const SET_CURRENT_WORKFLOW_MODULE = `${appIdentifier}/space/SET_CURRENT_WORKFLOW_MODULE`;

const initialState = {
  workflowModule: "behavior"
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  return produce((draft, action) => {
    switch (action.type) {
      case SET_CURRENT_WORKFLOW_MODULE:
        draft.workflowModule = action.moduleIdentifier;
        return;

      default:
        return state;
    }
  })(state, action);
}

// Action creators
export function setCurrentWorkflowModule(moduleIdentifier) {
  return {
    type: SET_CURRENT_WORKFLOW_MODULE,
    moduleIdentifier
  };
}
