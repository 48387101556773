import { useRef } from "react";
import { tree } from "configure-store.js";
import { validateTransform, createTransform } from "./transform-helpers.js";

export function useTransformMoving() {
  const initialGestureState = {
    isActive: false,
    isValid: false,
    start: {
      x: 0,
      y: 0
    },
    latest: {
      x: 0,
      y: 0
    },
    result: {
      minX: 0,
      maxX: 0,
      minY: 0,
      maxY: 0
    },
    original: {
      minX: 0,
      maxX: 0,
      minY: 0,
      maxY: 0
    }
  };
  const gesture = useRef(initialGestureState);

  const startTransformMovingGesture = (gridPointerPosition, original) => {
    // Start
    const isValid = validateTransform(original, tree);

    gesture.current = {
      ...initialGestureState,
      isActive: true,
      start: gridPointerPosition,
      latest: gridPointerPosition,
      isValid: isValid,
      result: original,
      original: original
    };
    return gesture.current;
  };
  const updateTransformMovingGesture = gridPointerPosition => {
    // Update
    if (gesture.current.isActive) {
      // Get delta position from start
      const deltaPosition = {
        x: gridPointerPosition.x - gesture.current.start.x,
        y: gridPointerPosition.y - gesture.current.start.y
      };

      const currentTransform = createTransform(
        {
          x: gesture.current.original.minX + deltaPosition.x,
          y: gesture.current.original.minY + deltaPosition.y
        },
        {
          x: gesture.current.original.maxX + deltaPosition.x - 1,
          y: gesture.current.original.maxY + deltaPosition.y - 1
        },
        []
      );
      const isValid = validateTransform(currentTransform, tree);

      gesture.current = {
        ...gesture.current,
        latest: gridPointerPosition,
        isValid: isValid,
        result: currentTransform
      };
    }

    return gesture.current;
  };
  const completeTransformMovingGesture = () => {
    let raw = {
      ...gesture.current,
      isActive: false
    };
    gesture.current = {
      ...initialGestureState
    };
    return raw;
  };
  const cancelTransformMovingGesture = () => {
    let raw = {
      ...gesture.current,
      isActive: false
    };
    gesture.current = {
      ...initialGestureState
    };
    return raw;
  };
  const transformMovingGestureIsActive = () => {
    return gesture.current.isActive;
  };
  return {
    startTransformMovingGesture,
    updateTransformMovingGesture,
    completeTransformMovingGesture,
    cancelTransformMovingGesture,
    gesture,
    transformMovingGestureIsActive
  };
}
