import { createStore, applyMiddleware, combineReducers } from "redux";
import createSagaMiddleware from "redux-saga";
import { persistStore, persistReducer } from "redux-persist";
import SpatialTree from "./spatial-tree/spatial-tree.js";

// Import middleware:
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";
import storageSession from 'redux-persist/lib/storage/session'

// Import reducers:
import specification from "./specification/specification-duck.js";
import space from "./space/space-duck.js";
import toolbox from "./toolbox/toolbox-duck.js";
import workflowModuleSelection from "./workflow-module-selection/workflow-module-selection-duck.js";

export const tree = new SpatialTree();
const thunkMiddleware = thunk.withExtraArgument(tree);
const sagaMiddleware = createSagaMiddleware();
const middleware = [thunkMiddleware, sagaMiddleware];

const createStoreWithMiddleware = applyMiddleware(...middleware)(createStore);

const reducer = combineReducers({
  specification,
  space,
  toolbox
});

const persistConfig = {
  key: "namida-editor",
  storage: storage
};

const persistedReducer = persistReducer(persistConfig, reducer);

export let store = createStoreWithMiddleware(persistedReducer);
let persistorConfig = {};
export let persistor = persistStore(store, persistorConfig, () => {
  tree.rehydrate(store.getState().specification);
});
