export const tools = {
  byIdentifier: {
    moveViewport: {
      identifier: "moveViewport",
      icon: "faHandPaper",
      title: "Move the Viewport.",
      hover: {
        node: false,
        edge: false,
        mood: "neutral",
        grid: false
      }
    },
    drawNode: {
      identifier: "drawNode",
      icon: "faVectorSquare",
      title: "Draw Nodes.",
      hover: {
        node: false,
        edge: false,
        mood: "neutral",
        grid: true
      }
    },
    resizeNode: {
      identifier: "resizeNode",
      icon: "faExpandAlt",
      title: "Resize Nodes.",
      hover: {
        node: false,
        edge: false,
        mood: "neutral",
        grid: false
      }
    },
    moveNode: {
      identifier: "moveNode",
      icon: "faArrowsAlt",
      title: "Translate Nodes.",
      hover: {
        node: true,
        edge: false,
        mood: "neutral",
        grid: false
      }
    },
    drawEdge: {
      identifier: "drawEdge",
      icon: "faProjectDiagram",
      title: "Draw Edges.",
      hover: {
        node: true,
        edge: false,
        mood: "neutral",
        grid: false
      }
    },
    erase: {
      identifier: "erase",
      icon: "faEraser",
      title: "Erase Nodes and Edges.",
      hover: {
        node: true,
        edge: true,
        mood: "negative",
        grid: false
      }
    },
    // moveDown: {
    //   identifier: "moveNodeDown",
    //   icon: "faUndo",
    //   title: "Undo",
    //   hover: {
    //     node: true,
    //     edge: false,
    //     mood: "negative",
    //     grid: false
    //   }
    // }

  },
  identifiers: [
    "moveViewport",
    "drawNode",
    "resizeNode",
    "moveNode",
    "drawEdge",
    "erase",
    // "moveDown"
  ]
};
