export const defaultToolGridPixelSize = 50;
export function getToolGridPixelSize() {
  return defaultToolGridPixelSize;
}

export const zLayers = {
  background: 0,
  nodes: 500,
  nodeContent: 50000,
  edges: 60000,
  ghost: 80000,
  pointer: 499,
  gui: 82000
};
