import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { getNodes, getEdges, getSpaceOffset } from "selectors";
import Sketch from "react-p5";
import { getPixelsPerUnit, zLayers } from "layout";
import { NaiveEdge } from "./naive-edge.jsx";
import { getSelectedPrimaryTool } from "../../selectors";
import { getPPU } from "selectors";

const StyledEdgeWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const StyledSketch = styled(Sketch)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

const StyledSketchWrapper = styled.div.attrs(props => ({
  style: {
    visibility: props.show || props.show === undefined ? "visible" : "hidden",
    transform: `translate(${props.offset.x}px, ${props.offset.y}px)`
  }
}))`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: ${zLayers.edges + 20};
`;

export function NaiveEdgeCanvas(props) {
  const nodes = useSelector(getNodes);
  const edges = useSelector(getEdges);
  const spaceOffset = useSelector(getSpaceOffset);
  const p5Ref = useRef(undefined);
  const ppu = useSelector(getPPU);
  const pixelsPerUnit = ppu;
  const selectedPrimaryTool = useSelector(getSelectedPrimaryTool);

  const initialState = {
    identifiers: [],
    byIdentifier: {}
  };
  const [computedEdges, setComputedEdges] = useState(initialState);

  const computeEdgeRenderingSpecification = (
    identifier,
    firstTransform,
    secondTransform,
    selfReferencing = false
  ) => {
    let raw = {};

    // Set some general properties:
    raw.identifier = identifier;
    raw.selfReferencing = selfReferencing;

    // Calculate centers:
    raw.firstCenterX =
      firstTransform.minX + (firstTransform.maxX - firstTransform.minX) / 2;
    raw.firstCenterY =
      firstTransform.minY + (firstTransform.maxY - firstTransform.minY) / 2;
    raw.secondCenterX =
      secondTransform.minX + (secondTransform.maxX - secondTransform.minX) / 2;
    raw.secondCenterY =
      secondTransform.minY + (secondTransform.maxY - secondTransform.minY) / 2;

    // Determine the direction:

    let horizontalDistance = 0;
    let verticalDistance = 0;
    let firstIsLeft = false;
    let firstIsAbove = false;

    // Calculate the horizontal distance:
    if (firstTransform.maxX <= secondTransform.minX) {
      // ... first is left.
      horizontalDistance = secondTransform.minX - firstTransform.maxX;
      firstIsLeft = true;
    } else if (firstTransform.minX >= secondTransform.maxX) {
      // ... first is right.
      horizontalDistance = firstTransform.minX - secondTransform.maxX;
    } else {
      // ... neither right nor left -> use vertical.
      horizontalDistance = 0;
      // todo: handle hierarchy
    }

    // Calculate the vertical distance:
    if (firstTransform.maxY <= secondTransform.minY) {
      // ... first is above.
      verticalDistance = secondTransform.minY - firstTransform.maxY;
      firstIsAbove = true;
    } else if (firstTransform.minY >= secondTransform.maxY) {
      // ... first is beyond.
      verticalDistance = firstTransform.minY - secondTransform.maxY;
    } else {
      // ... neither right nor left -> use horizontal.
      verticalDistance = 0;
      // todo: handle hierarchy
    }

    // Set the dominant direction.
    raw.direction =
      horizontalDistance > verticalDistance ? "horizontal" : "vertical";

    // Determine whether we have a hierarchical situation and if yes determine the direction of the anchors.
    raw.inside = false;
    if (horizontalDistance === 0 && verticalDistance === 0) {
      raw.inside = true;

      // Get closest distance of the same sides.
      let upperDistance = Math.abs(firstTransform.minY - secondTransform.minY);
      let lowerDistance = Math.abs(firstTransform.maxY - secondTransform.maxY);
      let leftDistance = Math.abs(firstTransform.minX - secondTransform.minX);
      let rightDistance = Math.abs(firstTransform.maxX - secondTransform.maxX);

      // Determine the iniseide direction:
      if (upperDistance < lowerDistance) {
        if (upperDistance < leftDistance) {
          if (upperDistance < rightDistance) {
            raw.insideDirection = "top";
          } else {
            raw.insideDirection = "right";
          }
        } else {
          if (leftDistance < rightDistance) {
            raw.insideDirection = "left";
          } else {
            raw.insideDirection = "right";
          }
        }
      } else {
        if (lowerDistance < leftDistance) {
          if (lowerDistance < rightDistance) {
            raw.insideDirection = "bottom";
          } else {
            raw.insideDirection = "right";
          }
        } else {
          if (leftDistance < rightDistance) {
            raw.insideDirection = "left";
          } else {
            raw.insideDirection = "right";
          }
        }
      }
    }

    // Determine anchor points and the handle direction at the anchors:

    let firstAnchor = {
      x: 0,
      y: 0
    };
    let secondAnchor = {
      x: 0,
      y: 0
    };

    if (raw.inside === false) {
      if (raw.direction === "horizontal") {
        firstAnchor.x = firstIsLeft ? firstTransform.maxX : firstTransform.minX;
        firstAnchor.y = raw.firstCenterY;
        secondAnchor.x = firstIsLeft
          ? secondTransform.minX
          : secondTransform.maxX;
        secondAnchor.y = raw.secondCenterY;
      } else {
        firstAnchor.x = raw.firstCenterX;
        firstAnchor.y = firstIsAbove
          ? firstTransform.maxY
          : firstTransform.minY;
        secondAnchor.x = raw.secondCenterX;
        secondAnchor.y = firstIsAbove
          ? secondTransform.minY
          : secondTransform.maxY;
      }

      raw.fromFirstAnchorInX =
        raw.direction === "horizontal" ? (firstIsLeft ? 1 : -1) : 0;
      raw.fromFirstAnchorInY =
        raw.direction === "vertical" ? (firstIsAbove ? 1 : -1) : 0;
      raw.fromSecondAnchorInX = -raw.fromFirstAnchorInX;
      raw.fromSecondAnchorInY = -raw.fromFirstAnchorInY;
    } else {
      if (selfReferencing) {
        firstAnchor.x = firstTransform.maxX;
        firstAnchor.y = firstTransform.minY + 1.6;
        secondAnchor.x = secondTransform.maxX - 1;
        secondAnchor.y = secondTransform.minY;

        raw.fromFirstAnchorInX = 1;
        raw.fromFirstAnchorInY = 0;
        raw.fromSecondAnchorInX = 0;
        raw.fromSecondAnchorInY = -1;
      } else {
        if (raw.insideDirection === "top") {
          firstAnchor.x = raw.firstCenterX;
          firstAnchor.y = firstTransform.minY;
          secondAnchor.x = raw.secondCenterX;
          secondAnchor.y = secondTransform.minY;
        } else if (raw.insideDirection === "bottom") {
          firstAnchor.x = raw.firstCenterX;
          firstAnchor.y = firstTransform.maxY;
          secondAnchor.x = raw.secondCenterX;
          secondAnchor.y = secondTransform.maxY;
        } else if (raw.insideDirection === "left") {
          firstAnchor.x = firstTransform.minX;
          firstAnchor.y = raw.firstCenterY;
          secondAnchor.x = secondTransform.minX;
          secondAnchor.y = raw.secondCenterY;
        } else if (raw.insideDirection === "right") {
          firstAnchor.x = firstTransform.maxX;
          firstAnchor.y = raw.firstCenterY;
          secondAnchor.x = secondTransform.maxX;
          secondAnchor.y = raw.secondCenterY;
        }

        raw.fromFirstAnchorInX =
          raw.insideDirection === "right"
            ? 1
            : raw.insideDirection === "left"
            ? -1
            : 0;
        raw.fromFirstAnchorInY =
          raw.insideDirection === "bottom"
            ? 1
            : raw.insideDirection === "top"
            ? -1
            : 0;
        raw.fromSecondAnchorInX = raw.fromFirstAnchorInX;
        raw.fromSecondAnchorInY = raw.fromFirstAnchorInY;
      }
    }

    // Determine the resulting positions:

    const anchorIncomingOutgoingOffset = pixelsPerUnit / 3;

    raw.first = {
      x:
        spaceOffset.x +
        firstAnchor.x * pixelsPerUnit +
        raw.fromFirstAnchorInY * -anchorIncomingOutgoingOffset,
      y:
        spaceOffset.y +
        firstAnchor.y * pixelsPerUnit +
        raw.fromFirstAnchorInX * -anchorIncomingOutgoingOffset,
      xNoOffset:
        firstAnchor.x * pixelsPerUnit +
        raw.fromFirstAnchorInY * -anchorIncomingOutgoingOffset,
      yNoOffset:
        firstAnchor.y * pixelsPerUnit +
        raw.fromFirstAnchorInX * -anchorIncomingOutgoingOffset
    };
    raw.second = {
      x:
        spaceOffset.x +
        secondAnchor.x * pixelsPerUnit +
        raw.fromSecondAnchorInY * anchorIncomingOutgoingOffset,
      y:
        spaceOffset.y +
        secondAnchor.y * pixelsPerUnit +
        raw.fromSecondAnchorInX * anchorIncomingOutgoingOffset,
      xNoOffset:
        secondAnchor.x * pixelsPerUnit +
        raw.fromSecondAnchorInY * anchorIncomingOutgoingOffset,
      yNoOffset:
        secondAnchor.y * pixelsPerUnit +
        raw.fromSecondAnchorInX * anchorIncomingOutgoingOffset
    };

    raw.horizontalOffset = Math.abs(raw.second.x - raw.first.x) / 2;
    raw.verticalOffset = Math.abs(raw.second.y - raw.first.y) / 2;

    return raw;
  };

  useEffect(() => {
    // Compute the edges

    // console.log("nodes: ", nodes)
    // console.log("edges: ", edges)
    if (edges?.identifiers) {
      let currentComputedEdges = initialState;

      // Compute edges between nodes:
      for (let i = 0; i < edges.identifiers.length; i++) {
        const element = edges.byIdentifier[edges.identifiers[i]];

        const firstTransform = nodes.byIdentifier[element.from.identifier].transform;
        const secondTransform = nodes.byIdentifier[element.to.identifier].transform;

        // if (props.tempNodeTransforms.hasOwnProperty(element.from.identifier))
        //   firstTransform = props.tempNodeTransforms[element.from.identifier];
        // if (props.tempNodeTransforms.hasOwnProperty(element.to.identifier))
        //   secondTransform = props.tempNodeTransforms[element.to.identifier];

        // Compute a edge rendering specification and add it to the computed edges:
        const edgeRenderingSpecification = computeEdgeRenderingSpecification(
          element.identifier,
          firstTransform,
          secondTransform,
          element.from.identifier === element.to.identifier
        );
        currentComputedEdges.identifiers.push(edgeRenderingSpecification.identifier);
        currentComputedEdges.byIdentifier[edgeRenderingSpecification.identifier] = edgeRenderingSpecification;
        
      }

      // Compute edges of entry points:
      // let temporalIdentifierCounter = 0;
      // for (let i = 0; i < nodes.identifiers.length; i++) {
      //   const element = nodes.byIdentifier[nodes.identifiers[i]];

      //   if (element.entryPoints.default) {
      //     const firstTransform = {
      //       minX: element.transform.minX,
      //       maxX: element.transform.minX,
      //       minY: element.transform.minY,
      //       maxY: element.transform.minY
      //     };
      //     const secondTransform =
      //       nodes.byIdentifier[element.entryPoints.default].transform;

      //       // Compute a edge rendering specification and add it to the computed edges:
      //     const edgeRenderingSpecification =computeEdgeRenderingSpecification(
      //       "temp" + temporalIdentifierCounter,
      //       firstTransform,
      //       secondTransform
      //     );
      //     currentComputedEdges.identifiers.push(edgeRenderingSpecification.identifier);
      //     currentComputedEdges.byIdentifier[edgeRenderingSpecification.identifier] = edgeRenderingSpecification;
          
      //     temporalIdentifierCounter++;
      //   }
      // }

      // Update the computed edges.
      setComputedEdges(currentComputedEdges);
    }

    return () => {};
  }, [edges, spaceOffset, nodes]);

  useEffect(() => {
    if (p5Ref.current) {
      if (p5Ref.current.hasOwnProperty("redraw")) {
        p5Ref.current.redraw(1);
      }
    }

    return () => {};
  }, [computedEdges]);

  let sketchExample = {
    setup: (p5, canvasParentRef) => {
      p5.createCanvas(props.spaceRect.width, props.spaceRect.height).parent(
        canvasParentRef
      ); // use parent to render canvas in this ref (without that p5 render this canvas outside your component)
      p5.noLoop();
      p5Ref.current = {
        redraw: n => {
          p5.redraw(n);
        }
      };
    },
    draw: p5 => {
      p5.clear();
      p5.background(0, 0);

      // Render the sketch parts of the computed edges:
      if (computedEdges && computedEdges.identifiers) {
        for (let i = 0; i < computedEdges.identifiers.length; i++) {
          const current =
            computedEdges.byIdentifier[computedEdges.identifiers[i]];

          p5.strokeWeight(3);
          p5.noFill();

          // Render the line of the edge:
          if (current.selfReferencing === false) {
            const MIN_HANDLE_DISTANCE = pixelsPerUnit * 2;
            p5.bezier(
              current.first.x,
              current.first.y,
              current.first.x +
                Math.max(current.horizontalOffset, MIN_HANDLE_DISTANCE) *
                  current.fromFirstAnchorInX,
              current.first.y +
                Math.max(current.verticalOffset, MIN_HANDLE_DISTANCE) *
                  current.fromFirstAnchorInY,
              current.second.x +
                Math.max(current.horizontalOffset, MIN_HANDLE_DISTANCE) *
                  current.fromSecondAnchorInX,
              current.second.y +
                Math.max(current.verticalOffset, MIN_HANDLE_DISTANCE) *
                  current.fromSecondAnchorInY,
              current.second.x,
              current.second.y
            );
          } else {
            const SELF_REFERENCING_RADIUS = pixelsPerUnit * 2.65;
            p5.arc(
              current.first.x,
              current.second.y,
              SELF_REFERENCING_RADIUS,
              SELF_REFERENCING_RADIUS,
              3.14159,
              1.5708
            );
          }

          // Render the arrowhead of the edge:

          const ARROWHEAD_LONG_OFFSET = pixelsPerUnit / 3;
          const ARROWHEAD_SHORT_OFFSET = pixelsPerUnit / 8;

          p5.fill("#000000");
          p5.triangle(
            current.second.x,
            current.second.y,
            current.second.x +
              (current.fromSecondAnchorInX !== 0
                ? current.fromSecondAnchorInX * ARROWHEAD_LONG_OFFSET
                : ARROWHEAD_SHORT_OFFSET),
            current.second.y +
              (current.fromSecondAnchorInY !== 0
                ? current.fromSecondAnchorInY * ARROWHEAD_LONG_OFFSET
                : ARROWHEAD_SHORT_OFFSET),
            current.second.x +
              (current.fromSecondAnchorInX !== 0
                ? current.fromSecondAnchorInX * ARROWHEAD_LONG_OFFSET
                : -ARROWHEAD_SHORT_OFFSET),
            current.second.y +
              (current.fromSecondAnchorInY !== 0
                ? current.fromSecondAnchorInY * ARROWHEAD_LONG_OFFSET
                : -ARROWHEAD_SHORT_OFFSET)
          );
        }
      }
    },
    windowResized: p5 => {
      p5.resizeCanvas(props.spaceRect.width, props.spaceRect.height);
      p5.redraw(1);
    }
  };

  // Add the react component parts of the computed edges:

  let edgeArray = [];
  computedEdges.identifiers.forEach(key => {
    const currentComputedEdge = computedEdges.byIdentifier[key];
    if (edges.identifiers.includes(key)) {
      let position = {
        x:
          Math.min(
            currentComputedEdge.first.xNoOffset,
            currentComputedEdge.second.xNoOffset
          ) +
          (Math.max(
            currentComputedEdge.first.xNoOffset,
            currentComputedEdge.second.xNoOffset
          ) -
            Math.min(
              currentComputedEdge.first.xNoOffset,
              currentComputedEdge.second.xNoOffset
            )) /
            2,
        y:
          Math.min(
            currentComputedEdge.first.yNoOffset,
            currentComputedEdge.second.yNoOffset
          ) +
          (Math.max(
            currentComputedEdge.first.yNoOffset,
            currentComputedEdge.second.yNoOffset
          ) -
            Math.min(
              currentComputedEdge.first.yNoOffset,
              currentComputedEdge.second.yNoOffset
            )) /
            2
      };
      if (currentComputedEdge.selfReferencing) {
        position.x = position.x + pixelsPerUnit * 1.8;
        position.y = position.y - pixelsPerUnit * 1.3;
      }
      edgeArray.push(
        <NaiveEdge
          key={key}
          specification={edges.byIdentifier[key]}
          position={position}
          selectedPrimaryTool={selectedPrimaryTool}
        ></NaiveEdge>
      );
    }
  });

  return (
    <StyledEdgeWrapper pixelsPerUnit={ppu}>
      {edgeArray}
      <StyledSketchWrapper offset={props.offset} show={props.show}>
        {props.spaceRect.width > 0 && props.spaceRect.height > 0 && (
          <StyledSketch
            setup={sketchExample.setup}
            draw={sketchExample.draw}
            windowResized={sketchExample.windowResized}
          />
        )}
      </StyledSketchWrapper>
    </StyledEdgeWrapper>
  );
}
