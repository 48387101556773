// Import specific tool selectors:
import { tools } from "./tools/tools.js";

export function getSelectedPrimaryTool(state) {
  return tools.byIdentifier[state.toolbox.selectedPrimaryTool];
}

export function primaryToolGestureIsActive(state) {
  return state[getSelectedPrimaryTool(state).storeKey].gesture.isActive;
}
