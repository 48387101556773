let uuidv4 = require("uuid/v4");

export function createNodeSpecification(
  transform,
  withIdentifier = true,
  withSmachAction = true
) {
  let specification = {
    name: "Alpha",
    identifier: "initial_timeline_condition",
    parent: "",
    children: [],
    transitions: [],
    entryPoints: {},
    transform: transform
  };

  if (withIdentifier) {
    specification.identifier = uuidv4();
  }

  if (withSmachAction) {
    specification.smachAction = {
      identifier: "",
      parameters: ""
    };
  }

  return specification;
}

export function createEdgeSpecification(fromTarget, toTarget, priority = 1) {
  let specification = {
    identifier: uuidv4(),
    priority: priority,
    from: fromTarget,
    to: toTarget,
    if: {
      outcome: "succeeded"
    }
  };

  return specification;
}

export function useRelevantEdgeExtractor(nodeId) {}

export function extractRelevantEdges(specification, nodeId) {
  let relevantEdges = [];
  const node = specification.nodes.byIdentifier[nodeId];

  for (let i = 0; i < node.transitions.length; i++) {
    const edgeId = node.transitions[i];

    relevantEdges.push(specification.edges.byIdentifier[edgeId]);
  }

  return relevantEdges;
}

export function createNodeTemplate(node) {
  let raw = createNodeSpecification(node.transform);
  raw.identifier = node.identifier;
  raw.name = node.name;
  raw.smachAction = node.smachAction;

  return raw;
}
