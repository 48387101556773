import styled from "styled-components";
import { zLayers } from "layout";

export const Input = styled.input`
  padding: 10px 20px;
  border: 1px solid #b7b7b7;
  -webkit-border-radius: 51px;
  border-radius: 51px;
  font: normal 14px / normal "Lucida Console", Monaco, monospace;
  color: rgba(38, 38, 38, 1);
  -o-text-overflow: clip;
  text-overflow: clip;
  background: rgba(90, 90, 90, 0.1);
  -webkit-box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.2) inset;
  box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.2) inset;
  -webkit-transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1);
  -moz-transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1);
  -o-transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1);
  transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1);
  z-index: ${zLayers.gui};
`;
