import React, { useContext, useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NodeGhost } from "node/components/node-ghost.jsx";
import { getSpaceRect, getSpecification } from "selectors.js";
import {
  inputContext,
  useDrawGestureEvents,
  useInput,
  useGridPointerPosition
} from "input";
import { useTransformMoving } from "./../helpers/transform-moving-module.js";
import { addNode, removeNode, addEdge, setSelection, moveNodeTransform, saveHistory } from "specification";
import {
  extractRelevantEdges,
  createNodeTemplate
} from "specification/specification-helpers.js";
import {
  subtract,
  add
} from "transform-helpers.js";

export function MoveNodeTool(props) {
  const ic = useContext(inputContext);
  const dispatch = useDispatch();
  const spaceRect = useSelector(getSpaceRect);
  const specification = useSelector(getSpecification);
  const gridPointerPosition = useGridPointerPosition(ic.layer);

  const initialGestureState = {
    isValid: false,
    isActive: false,
    transform: {
      minX: 0,
      maxX: 0,
      minY: 0,
      maxY: 0
    },
    updateCalled: false
  };
  const [gesture, setGesture] = useState(initialGestureState);

  const initialExtraction = {
    node: {},
    edges: [],
    children: [],
  };
  const extraction = useRef(initialExtraction);

  const {
    startTransformMovingGesture,
    updateTransformMovingGesture,
    completeTransformMovingGesture,
    cancelTransformMovingGesture,
    transformMovingGestureIsActive
  } = useTransformMoving();

  useEffect(() => {
    console.log(JSON.stringify(gesture));
  }, [gesture]);

  useInput(
    ic.layer,
    "NODE_DOWN",
    event => {
      // get the transform of the current node.
      dispatch(saveHistory());

      extraction.current = {
        node: createNodeTemplate(event.detail.nodeSpecification),
        edges: extractRelevantEdges(
          specification,
          event.detail.nodeSpecification.identifier
        ),
        children: event.detail.nodeSpecification.children
      };
      dispatch(removeNode(extraction.current.node.identifier));

      const transformMovingGesture = startTransformMovingGesture(
        gridPointerPosition.current,
        event.detail.nodeSpecification.transform
      );

      // Set state from gesture current
      setGesture(prev => {
        return {
          ...initialGestureState,
          isActive: true,
          isValid: transformMovingGesture.isValid,
          transform: transformMovingGesture.result
        };
      });
    },
    [specification]
  );

  const [temp, setTemp] = useState("");
  useEffect(() => {
    if (!gesture.updateCalled){
      dispatch(setSelection([temp]))
    }
  }, [temp]);

  useDrawGestureEvents(
    ic.layer,
    (event, context) => {
      // Start -> Resizing is triggered by speical resize handle events.
    },
    (event, context) => {
      // Update
      // console.log("update")

      if (transformMovingGestureIsActive()) {
        const transformMovingGesture = updateTransformMovingGesture(
          context.latest.gridPointerPosition
        );

        // Set state from gesture current
        setGesture(prev => {
          return {
            ...prev,
            isActive: transformMovingGesture.isActive,
            isValid: transformMovingGesture.isValid,
            transform: transformMovingGesture.result,
            updateCalled: true
          };
        });
      }
    },
    (event, context) => {
      if (transformMovingGestureIsActive()) {
        const transformMovingGesture = completeTransformMovingGesture(context);

        let newNode = {
          ...extraction.current.node
        };

        // setTemp(newNode.identifier);
        // if (!gesture.updateCalled){
        dispatch(setSelection([newNode.identifier]))
        // }
        console.log(newNode)
        console.log("newNode.Transform: ", newNode.transform, "transformMovingGesture.result: ", transformMovingGesture.result)
        console.log("Differenz test: ", subtract(transformMovingGesture.result, newNode.transform))
        // If the gesture is valid we use the new transform.
        if (transformMovingGesture.isValid) {
          let diff = subtract(transformMovingGesture.result, newNode.transform)
          newNode.transform = transformMovingGesture.result;
          for(let child of extraction.current.children) {
            console.log("moving ", child, " by ", {x: diff.minX, y: diff.minY})
            dispatch(moveNodeTransform(child, {x: diff.minX, y: diff.minY}));
          }

        }
        
        dispatch(addNode(newNode));
        for (let i = 0; i < extraction.current.edges.length; i++) {
          const edge = extraction.current.edges[i];
          dispatch(addEdge(edge));
        }

        // Finished -> reset local gesture.
        setGesture(prev => {
          return {
            ...initialGestureState
          };
        });
      }
    },
    () => {
      // Cancel.
      if (transformMovingGestureIsActive()) {
        cancelTransformMovingGesture();
        dispatch(addNode(extraction.current.node));
        for (let i = 0; i < extraction.current.edges.length; i++) {
          const edge = extraction.current.edges[i];
          dispatch(addEdge(edge));
        }
        // Finished -> reset local gesture.
        setGesture(prev => {
          return {
            ...initialGestureState
          };
        });
      }
    }
  );

  return (
    <NodeGhost
      show={gesture.isActive}
      transform={gesture.transform}
      isValid={gesture.isValid}
      anchor={spaceRect}
    ></NodeGhost>
  );
}
