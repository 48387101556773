import React, {useEffect} from "react";
import "./App.css";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor} from "./configure-store.js";
import { WorkflowModuleSelection } from "./workflow-module-selection/components";
import "./styles.css";
import {Space} from "./space/components";

function App() {
  const divStyle = {
    width: "100%",
    height: "100%"
  };

    useEffect(() => {
        window.addEventListener(
            "dragstart",
            function(e) {
                e = e || window.event;
                e.preventDefault();
            },
            false
        );
        window.addEventListener(
            "dragover",
            function(e) {
                e = e || window.event;
                e.preventDefault();
            },
            false
        );
        window.addEventListener(
            "drop",
            function(e) {
                e = e || window.event;
                e.preventDefault();
            },
            false
        );
    }, []);

  return (
    <div style={divStyle}>
      <Provider store={store}>
          <Space />
      </Provider>
    </div>
  );
}

export default App;
