import React, { useContext, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NodeGhost } from "node/components/node-ghost.jsx";
import { getSpaceRect } from "selectors.js";
import { inputContext, useDrawGestureEvents } from "input";
import { createNodeSpecification } from "specification/specification-helpers.js";
import { addNode, saveHistory } from "specification";
import { useTransformDrawing } from "./../helpers/transform-drawing-module.js";

export function DrawNodeTool(props) {
  const spaceRect = useSelector(getSpaceRect);
  const dispatch = useDispatch();
  const ic = useContext(inputContext);

  const initialGestureState = {
    isValid: false,
    isActive: false,
    transform: {
      minX: 0,
      maxX: 0,
      minY: 0,
      maxY: 0
    }
  };
  const [currentGestureResult, setCurrentGestureResult] = useState(
    initialGestureState
  );

  let {
    startTransformDrawingGesture,
    updateTransformDrawingGesture,
    completeTransformDrawingGesture,
    cancelTransformDrawingGesture
  } = useTransformDrawing();

  useDrawGestureEvents(
    ic.layer,
    (event, context) => {
      // Start
      // Determine anchors
      const anchors = [];

      const gesture = startTransformDrawingGesture(
        context.start.gridPointerPosition,
        anchors
      );

      // Set state from gesture current
      setCurrentGestureResult(prev => {
        return {
          ...prev,
          isActive: context.isActive,
          isValid: gesture.isValid,
          transform: gesture.result
        };
      });
    },
    (event, context) => {
      // Update
      const gesture = updateTransformDrawingGesture(
        context.latest.gridPointerPosition
      );

      // Set state from gesture current
      setCurrentGestureResult(prev => {
        return {
          ...prev,
          isActive: context.isActive,
          isValid: gesture.isValid,
          transform: gesture.result
        };
      });
    },
    (event, context) => {
      // Complete.
      
      const gesture = completeTransformDrawingGesture(context);

      setCurrentGestureResult(prev => {
        return {
          ...prev,
          isActive: context.isActive,
          isValid: gesture.isValid,
          transform: gesture.result
        };
      });
       if (gesture.isValid) {
        dispatch(saveHistory());
        dispatch(addNode(createNodeSpecification(gesture.result)));
      }
    },
    () => {
      // Cancel.
      cancelTransformDrawingGesture();
    }
  );

  return (
    <NodeGhost
      show={currentGestureResult.isActive}
      transform={currentGestureResult.transform}
      isValid={currentGestureResult.isValid}
      anchor={spaceRect}
    ></NodeGhost>
  );
}
