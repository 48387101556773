import { useInput, dispatchInputEvent } from "./../input-core.js";
import { useRef } from "react";

// Topics:

export const ENTER_TARGET = `ENTER_TARGET`;
export const LEAVE_TARGET = `LEAVE_TARGET`;
export const CLEAR_TARGET = `LEAVE_TARGET`;

// Detector:

export function useHoveredTargetDetector(layer, identifier, type) {
  let mouseEnterHandler = () => {
    dispatchEnterTarget(layer, identifier, type);
  };
  let mouseLeaveHandler = () => {
    dispatchLeaveTarget(layer, identifier, type);
  };

  return {
    mouseEnterHandler,
    mouseLeaveHandler
  };
}

// Dispatchers:

export function dispatchEnterTarget(layer, identifier, type) {
  dispatchInputEvent(layer, ENTER_TARGET, {
    identifier,
    type
  });
}

export function dispatchLeaveTarget(layer, identifier, type) {
  dispatchInputEvent(layer, LEAVE_TARGET, {
    identifier,
    type
  });
}

export function dispatchClearTarget(layer) {
  dispatchInputEvent(layer, CLEAR_TARGET, {});
}

// Hooks:

export function useHoveredTarget(layer) {
  const initialState = {
    identifier: "",
    type: ""
  };
  const hover = useRef(initialState);

  useInput(
    layer,
    ENTER_TARGET,
    event => {
      hover.current = {
        ...hover.current,
        ...event.detail
      };
    },
    ["hover"]
  );

  useInput(
    layer,
    LEAVE_TARGET,
    event => {
      if (event.detail.identifier === hover.identifier) {
        hover.current = {
          ...initialState
        };
      } else {
        hover.current = {
          ...hover.current
        };
      }
    },
    ["hover"]
  );

  useInput(
    layer,
    CLEAR_TARGET,
    event => {
      hover.current = {
        ...initialState
      };
    },
    ["hover"]
  );

  return hover;
}
