import React, { useContext } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { getWidth, getHeight } from "transform-helpers.js";
import { zLayers } from "layout";
import {
  Nodelike,
  Grid,
  NodeLikeWrapper,
  ContentWrapper,
  Input
} from "base/components";
import { EntryPoints } from "./entry-points.jsx";
import { SmachAction } from "./smach-action.jsx";
import specification, { setNodeName } from "specification";
import {
  inputContext,
  useHoveredTargetDetector,
  useHoveredTarget,
  dispatchInputEvent
} from "input";
import { getSelectedPrimaryTool, getNodeDistance, getEdges, getSelection } from "selectors.js"
import { getPPU } from "selectors";
import { StyledNodelike } from "styling/color-palette.js";

const StyledNodeLikeWrapper = styled(NodeLikeWrapper)`
  z-index: ${props =>
    zLayers.nodes +
    10000 -
    getWidth(props.transform) * getHeight(props.transform)};
`;

const GridWrapper = styled.div`
  grid-area: middle;
  min-height: 0;
  min-width: 0;
  pointer-events: none;
`;

export const FullAreaItem = styled.div`
  grid-column-start: column-start;
  grid-column-end: column-end;
  grid-row-start: row-start;
  grid-row-end: row-end;
`;


// const neutralBorderColor = "#78DCE8";
// const hasParentBackgroundColor = "#a6eaed";
// const neutralBackgroundColor = "#d6eaed";
// const negativeBorderColor = "#FF6188";
// const negativeBackgroundColor = "#f2d2da";
// const isSelectedBackgroundColor = "#118b95"

// const StyledNodelike = styled(Nodelike).attrs(props => ({
//   borderColor:
//     props.mood === "negative" ? negativeBorderColor : neutralBorderColor,
//   backgroundColor:
//     props.selected ? isSelectedBackgroundColor :
//     props.specification.parent !== "" ? hasParentBackgroundColor :
//     props.mood === "negative" ? negativeBackgroundColor : neutralBackgroundColor
// }))`
//   box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.25);
//   ${({ borderColor, backgroundColor }) =>
//     `
//         background-color: ${backgroundColor};
//         border-color: ${borderColor};
//   `}
//   ${({ hovered, borderColor, backgroundColor }) =>
//     hovered &&
//     `
//         background-color: ${backgroundColor};
//         border-color: ${borderColor};
//   `}
// `;

const Name = styled(Input).attrs(props => {
  const ppu = props.pixelsPerUnit;
  const pixelsPerUnit = ppu;

  return {
    pixelsPerUnit
  };
})`
  border-radius: 4px;
  margin: ${props => props.pixelsPerUnit / 8}px;
  grid-area: row-top-start / column-center-start / row-top-end /
    column-right-end;
  min-width: 1em;
  overflow: hidden;
  place-self: stretch stretch;
  z-index: ${zLayers.nodeContent};
`;

const MiddleLines = styled.div.attrs(props => {
  const ppu = props.pixelsPerUnit;
  const pixelsPerUnit = ppu;

  return {
    pixelsPerUnit
  };
})`
  grid-area: middle;
  display: grid;
  grid-auto-rows: ${props => props.pixelsPerUnit}px;
`;

const ActionWrapper = styled.div`
  grid-area: 1 / 1 / 3 / 2;
`;

const ResizeToolWrapper = styled.div.attrs(props => {
  const pixelsPerUnit = props.pixelsPerUnit;

  return {
    pixelsPerUnit
  };
})`
  grid-column-start: column-start;
  grid-column-end: column-end;
  grid-row-start: row-start;
  grid-row-end: row-end;
  z-index: ${zLayers.nodeContent + 100};
  display: grid;
  grid-template-columns: ${props =>
    ` ${props.pixelsPerUnit / 2}px auto ${props.pixelsPerUnit / 2}px`};
  grid-template-rows: ${props =>
    `${props.pixelsPerUnit / 2}px auto  ${props.pixelsPerUnit / 2}px`};
  grid-template-areas:
    "top-left        top             top-right       "
    "left            middle          right           "
    "bottom-left     bottom          bottom-right    ";
`;

const BorderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TopLeftWrapper = styled(BorderWrapper)`
  grid-area: top-left;
`;
const TopWrapper = styled(BorderWrapper)`
  grid-area: top;
`;
const TopRightWrapper = styled(BorderWrapper)`
  grid-area: top-right;
`;
const RightWrapper = styled(BorderWrapper)`
  grid-area: right;
`;
const BottomRightWrapper = styled(BorderWrapper)`
  grid-area: bottom-right;
`;
const BottomWrapper = styled(BorderWrapper)`
  grid-area: bottom;
`;
const BottomLeftWrapper = styled(BorderWrapper)`
  grid-area: bottom-left;
`;
const LeftWrapper = styled(BorderWrapper)`
  grid-area: left;
`;

const StyledResizeHandle = styled.div.attrs(props => {
  const pixelsPerUnit = props.pixelsPerUnit;

  return {
    pixelsPerUnit
  };
})`
  width: ${props => props.pixelsPerUnit / 2}px;
  height: ${props => props.pixelsPerUnit / 2}px;
  border-radius: 999px;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: ${props => props.index + 1};
  grid-row-end: ${props => props.index + 2};
  background-color: #aaa;
  cursor: pointer;
  z-index: ${zLayers.gui + 10};
  &:hover {
    background-color: #bbb;
  }
  &.active {
    background-color: orange;
  }
`;

function interpolate(a, b, t) {
  return {
    minX: a.minX * (1 - t) + b.minX * t,
    maxX: a.maxX * (1 - t) + b.maxX * t,
    minY: a.minY * (1 - t) + b.minY * t,
    maxY: a.maxY * (1 - t) + b.maxY * t,
  }
}

export function Node(props) {
  const dispatch = useDispatch();
  const specification = props.specification;
  const anchor = props.anchor;
  const ic = useContext(inputContext);
  const selectedPrimaryTool = useSelector(getSelectedPrimaryTool);
  const ppu = useSelector(getPPU);
  const selection = useSelector(getSelection);
  const nodeDistances = useSelector(getNodeDistance);
  const edges = useSelector(getEdges);

  const handleChange = event => {
    dispatch(setNodeName(specification.identifier, event.target.value));
  };

  const { mouseEnterHandler, mouseLeaveHandler } = useHoveredTargetDetector(
    ic.layer,
    props.specification.identifier,
    "node"
  );

  const hover = useHoveredTarget(ic.layer);
  const hovered = hover.current.identifier === props.specification.identifier;
  const selected = selection.includes(props.specification.identifier);
  const nodeDistance = nodeDistances[props.specification.identifier];


  // console.log(props.specification.name);
  // for (let edge of props.specification.transitions){
  //   console.log(edge, edges.byIdentifier[edge]);
  // }

  const dispatchNodeDownEvent = function() {
    dispatchInputEvent(ic.layer, "NODE_DOWN", {
      nodeSpecification: specification
    });
  };
  const dispatchResizeHandleEvent = function(direction) {
    dispatchInputEvent(ic.layer, "RESIZE_HANDLE_DOWN", {
      direction: direction,
      nodeSpecification: specification
    });
  };

  if (!props.tempNodeTransforms[specification.identifier])
    props.tempNodeTransforms[specification.identifier] = specification.transform;
  let currentTransform = interpolate(props.tempNodeTransforms[specification.identifier], specification.transform, 0.2);
  props.tempNodeTransforms[specification.identifier] = currentTransform;

  return (
    <StyledNodeLikeWrapper
      className={hovered ? "hovered" : ""}
      anchor={anchor}
      transform={currentTransform}
      pixelsPerUnit={ppu}
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
      onMouseDown={() => {
        dispatchNodeDownEvent();
      }}
    >
      {selectedPrimaryTool?.identifier === "resizeNode" && hovered && (
        <ResizeToolWrapper pixelsPerUnit={ppu}>
          <TopLeftWrapper>
            <StyledResizeHandle
              pixelsPerUnit={ppu}
              onMouseDown={() => {
                dispatchResizeHandleEvent("top-left");
              }}
            />
          </TopLeftWrapper>
          <TopWrapper>
            <StyledResizeHandle
              pixelsPerUnit={ppu}
              onMouseDown={() => {
                dispatchResizeHandleEvent("top");
              }}
            />
          </TopWrapper>
          <TopRightWrapper>
            <StyledResizeHandle
              pixelsPerUnit={ppu}
              onMouseDown={() => {
                dispatchResizeHandleEvent("top-right");
              }}
            />
          </TopRightWrapper>
          <RightWrapper>
            <StyledResizeHandle
              pixelsPerUnit={ppu}
              onMouseDown={() => {
                dispatchResizeHandleEvent("right");
              }}
            />
          </RightWrapper>
          <BottomRightWrapper>
            <StyledResizeHandle
              pixelsPerUnit={ppu}
              onMouseDown={() => {
                dispatchResizeHandleEvent("bottom-right");
              }}
            />
          </BottomRightWrapper>
          <BottomWrapper>
            <StyledResizeHandle
              pixelsPerUnit={ppu}
              onMouseDown={() => {
                dispatchResizeHandleEvent("bottom");
              }}
            />
          </BottomWrapper>
          <BottomLeftWrapper>
            <StyledResizeHandle
              pixelsPerUnit={ppu}
              onMouseDown={() => {
                dispatchResizeHandleEvent("bottom-left");
              }}
            />
          </BottomLeftWrapper>
          <LeftWrapper>
            <StyledResizeHandle
              pixelsPerUnit={ppu}
              onMouseDown={() => {
                dispatchResizeHandleEvent("left");
              }}
            />
          </LeftWrapper>
        </ResizeToolWrapper>
      )}
      <ContentWrapper
        pixelsPerUnit={ppu}>
        {/* <button style={{backgroundColor: "#FF00FF"}} onClick={()=>{specification.children[0]}}>
          eksdieksdieksdieksdieksdieksdieksdieksdieksdieksdi
        </button> */}
        <GridWrapper>
          <Grid show={hovered} />
        </GridWrapper>
        <Name type="text" pixelsPerUnit={ppu} value={specification.name} onChange={handleChange} />
        <MiddleLines pixelsPerUnit={ppu}>
        </MiddleLines>
        <EntryPoints entryPoints={specification.entryPoints} />
      </ContentWrapper>
      <StyledNodelike
        hovered={selectedPrimaryTool?.hover.node && hovered}
        mood={hovered ? selectedPrimaryTool?.hover.mood : ""}
        specification={specification}
        selected={selected}
        distance={nodeDistance}
      >
        <span style={{
          fontSize: "40px"
        }}>{nodeDistance}</span>
      </StyledNodelike>
    </StyledNodeLikeWrapper>
  );
}
