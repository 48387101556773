import { useInput, dispatchInputEvent } from "./../input-core.js";
import { useState, useRef } from "react";
import { useHoveredTarget } from "./hovered-target";
import {
  useGridPointerPosition,
  useGridPointerPositionEvent,
  usePixelPointerPosition
} from "./pointer-position";

// Topics:

export const START_DRAW_GESTURE = `START_DRAW_GESTURE`;
export const UPDATE_DRAW_GESTURE = `UPDATE_DRAW_GESTURE`;
export const COMPLETE_DRAW_GESTURE = `COMPLETE_DRAW_GESTURE`;
export const CANCEL_DRAW_GESTURE = `CANCEL_DRAW_GESTURE`; // todo implement this

// Detector:

export function useDrawGestureDetector(layer) {
  const gestureIsActive = useRef(false);
  const hoverTarget = useHoveredTarget(layer);
  const gridPointerPosition = useGridPointerPosition(layer);
  const pixelPointerPosition = usePixelPointerPosition(layer);
  useGridPointerPositionEvent(layer, (event, data) => {
    if (gestureIsActive.current) {
      dispatchUpdateDrawGesture(
        layer,
        data.gridPointerPosition,
        data.pixelPointerPosition,
        hoverTarget.current
      );
    }
  });

  const mouseDownHandler = () => {
    gestureIsActive.current = true;
    dispatchStartDrawGesture(
      layer,
      gridPointerPosition.current,
      pixelPointerPosition,
      hoverTarget.current
    );
  };

  const mouseUpHandler = () => {
    if (gestureIsActive) {
      gestureIsActive.current = false;
      dispatchCompleteDrawGesture(
        layer,
        gridPointerPosition.current,
        pixelPointerPosition,
        hoverTarget.current
      );
    }
  };

  const mouseLeaveHandler = () => {
    if (gestureIsActive) {
      gestureIsActive.current = false;
      dispatchCancelDrawGesture(layer);
    }
  };

  return {
    mouseDownHandler,
    mouseUpHandler,
    mouseLeaveHandler
  };
}

// Dispatchers:

export function dispatchStartDrawGesture(
  layer,
  gridPointerPosition,
  pixelPointerPosition,
  hoverTarget
) {
  dispatchInputEvent(layer, START_DRAW_GESTURE, {
    gridPointerPosition,
    pixelPointerPosition,
    hoverTarget
  });
}

export function dispatchUpdateDrawGesture(
  layer,
  gridPointerPosition,
  pixelPointerPosition,
  hoverTarget
) {
  dispatchInputEvent(layer, UPDATE_DRAW_GESTURE, {
    gridPointerPosition,
    pixelPointerPosition,
    hoverTarget
  });
}

export function dispatchCompleteDrawGesture(
  layer,
  gridPointerPosition,
  pixelPointerPosition,
  hoverTarget
) {
  dispatchInputEvent(layer, COMPLETE_DRAW_GESTURE, {
    gridPointerPosition,
    pixelPointerPosition,
    hoverTarget
  });
}

export function dispatchCancelDrawGesture(layer) {
  dispatchInputEvent(layer, CANCEL_DRAW_GESTURE, {});
}

// Hooks:

export function useDrawGesture(layer) {
  const initialState = {
    start: {
      gridPointerPosition: {},
      pixelPointerPosition: {},
      hoverTarget: {}
    },
    latest: {
      gridPointerPosition: {},
      pixelPointerPosition: {},
      hoverTarget: {}
    },
    isActive: false
  };
  const [context, setContext] = useState(initialState);

  useInput(
    layer,
    START_DRAW_GESTURE,
    event => {
      let localContext = context;
      localContext.start = event.detail;
      localContext.isActive = true;
      setContext(localContext);
    },
    [context]
  );

  useInput(
    layer,
    UPDATE_DRAW_GESTURE,
    event => {
      let localContext = context;
      localContext.latest = event.detail;
      localContext.isActive = true;
      setContext(localContext);
    },
    [context]
  );

  useInput(
    layer,
    COMPLETE_DRAW_GESTURE,
    event => {
      let localContext = context;
      localContext.latest = event.detail;
      localContext.isActive = false;
      setContext(initialState);
    },
    [context]
  );

  useInput(
    layer,
    CANCEL_DRAW_GESTURE,
    event => {
      setContext(context => {
        return initialState;
      });
    },
    [context]
  );

  return context;
}

export function useDrawGestureEvents(
  layer,
  onDrawStart,
  onDrawUpdate,
  onDrawComplete,
  onDrawCancel,
  dependencies = ["context"]
) {
  const initialState = {
    start: {
      gridPointerPosition: {},
      pixelPointerPosition: {},
      hoverTarget: {}
    },
    latest: {
      gridPointerPosition: {},
      pixelPointerPosition: {},
      hoverTarget: {}
    },
    isActive: false
  };
  const context = useRef(initialState);

  useInput(
    layer,
    START_DRAW_GESTURE,
    event => {
      let localContext = context.current;
      localContext.start = event.detail;
      localContext.isActive = true;
      context.current = localContext;
      onDrawStart(event, localContext);
    },
    dependencies
  );

  useInput(
    layer,
    UPDATE_DRAW_GESTURE,
    event => {
      let localContext = context.current;
      localContext.latest = event.detail;
      localContext.isActive = true;
      context.current = localContext;
      onDrawUpdate(event, localContext);
    },
    dependencies
  );

  useInput(
    layer,
    COMPLETE_DRAW_GESTURE,
    event => {
      let localContext = context.current;
      localContext.latest = event.detail;
      localContext.isActive = false;
      context.current = localContext;
      onDrawComplete(event, localContext);
    },
    dependencies
  );

  useInput(
    layer,
    CANCEL_DRAW_GESTURE,
    event => {
      context.current = initialState;
      onDrawCancel(event);
    },
    dependencies
  );
}
